import classNames from "classnames";
import { Field, FormRenderProps } from "react-final-form";
import AssignmentLabel from "../../../../components/AssignmentLabel/AssignmentLabel";
import FormStruct from "../../../../components/Form/FormStruct/FormStruct";
import i18n from "../../../../i18n";
import LabeledInput from "../../../abstract-ui/forms/LabeledInput";
import BFInput from "../../../abstract-ui/forms/input/BFInput";
import { Resubmission } from "../../RSInterfaces";
import RSService from "../../RSService";
interface ResubmissionDoneFormProps {
  resubmission: Resubmission;
  listIdentifier: string;
  onClose: () => void;
  closeResubmission?: (id?: string) => void;
}

const ResubmissionDoneForm = (props: ResubmissionDoneFormProps) => {
  const initialValues = {
    id: props.resubmission._id,
    feedbackComment: "",
  };

  return (
    <div className={classNames("resubmission-done-form")}>
      <FormStruct
        title={i18n.t("Resubmission.Title.Done", "Wiedervorlage abschließen")}
        className="rs-done-form"
        initialValues={initialValues}
        onSubmit={async (values) => {
          await RSService.submitResubmissionDoneAsync(
            values,
            props.listIdentifier
          );
          props.onClose();
          if (props.closeResubmission) {
            props.closeResubmission(props.resubmission._id);
          }
        }}
        onAbort={(form: FormRenderProps) => {
          form.form.reset();
          props.onClose();
        }}
        submitText={i18n.t("Resubmission.Buttons.Done", "Abschließen")}
        render={(formProps) => {
          return (
            <div className="fields">
              <div className={`__h3 margin-bottom-10 margin-top-10`}>
                {i18n.t("Resubmission.Titles.Feedback", "Rückmeldung")}
              </div>
              <div
                className={`__description-text margin-bottom-10 margin-top-10`}
              >
                {i18n.t(
                  "Resubmission.Form.Done.Feedback",
                  "Folgende Personen bzw. Teams erhalten eine Benachrichtigung über den Abschluss dieser wieder vorlage:"
                )}
              </div>
              <div className="__form-row">
                <div className="__field">
                  <LabeledInput
                    type="sub"
                    label={i18n.t("Resubmission.Labels.Assignees", "Empfänger")}
                  >
                    <AssignmentLabel
                      users={props.resubmission.data.feedbackRecipients.users}
                      teams={props.resubmission.data.feedbackRecipients.teams}
                      clipAtCount={6}
                      asList={true}
                    />
                  </LabeledInput>
                </div>
              </div>
              <div className="__form-row">
                <div className={classNames("__field", "__flex-1")}>
                  <Field name="feedbackComment">
                    {({ input, meta }) => {
                      return (
                        <BFInput
                          type="textarea"
                          label={i18n.t(
                            "Resubmission.Labels.FeedbackComment",
                            "Kommentar"
                          )}
                          {...input}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default ResubmissionDoneForm;
