import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import WrappedText from "@/components/WrappedText/WrappedText";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFRadioGroup from "@/modules/abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "@/modules/abstract-ui/forms/radio/BfRadio";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { useState } from "react";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBAgreementContractBasedata.scss";

export type ContractBaseDataType = {
  contractType: "business" | "private";
  id: string;
  displayName: string;
  note: null | string;
};
interface CBAgreementContractBasedataProps {
  data: ContractBaseDataType;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
}
const CBAgreementContractBasedata = (
  props: CBAgreementContractBasedataProps
) => {
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={[]}
      title={
        <>
          <span className={`padding-right-6`}>
            {i18n.t(
              "cb:RentalAgreement.Form.Fields.contract.title",
              "Vertragsdaten"
            )}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "md",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <CBAgreementContractBasedataForm
                        rentalAgreement={props.rentalAgreement}
                        onClose={close}
                      />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.contract.viewHint",
        "Informationen zum Vertrag"
      )}
      identifier="contract"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <>
        <div className={`__flex-wrap`}>
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Form.Fields.contractType",
              "Vertragsart"
            )}
            value={props.data.contractType}
            formatter={(value) =>
              value === "business"
                ? i18n.t("cb:RentalAgreement.Form.business", "Gewerblich")
                : i18n.t("cb:RentalAgreement.Form.private", "Privat")
            }
          />
          <BFValueDisplay
            label={i18n.t("cb:RentalAgreement.Form.Fields.ID", "ID")}
            value={props.data.id}
          />
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Form.Fields.displayName",
              "Anzeigename"
            )}
            value={props.data.displayName}
          />
        </div>

        <div className={`__flex-wrap margin-top-10`}>
          <BFValueDisplay
            label={i18n.t("cb:RentalAgreement.Form.Fields.note", "Notiz")}
            value={props.data.note}
            formatter={(value) => <WrappedText text={value} />}
          />
        </div>
      </>
    </BFPDFMarkerSection>
  );
};

export default CBAgreementContractBasedata;

const CBAgreementContractBasedataForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    id: props.rentalAgreement.data.id,
    displayName: props.rentalAgreement.data.displayName,
    note: props.rentalAgreement.data.note,
    contractType: props.rentalAgreement.data.contractType,
  });
  // const kind = ObjectKindStruct.getKind(
  //   OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.objectKindId
  // );

  return (
    <FormStruct
      initialValues={initialValues}
      onSubmit={async (values) => {
        await CBRentalService.updateRentalAgreementBaseData(
          props.rentalAgreement._id,
          values.id,
          values.displayName,
          values.note,
          values.contractType
        );
        props.onClose();
      }}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t("cb:RentalAgreement.Form.SectionTitle.basedate", "Vertragsdaten")
      )}
      description={i18n.t("")}
      submitText={i18n.t("Global.Buttons.save")}
      render={(formProps) => (
        <>
          <div className={`__field`}>
            <Field name="contractType" validate={FV.required()}>
              {({ input, meta }) => (
                <BFRadioGroup
                  inline
                  value={input.value}
                  onChange={(_value, checked) => input.onChange(checked)}
                  {...FV.getValidation(meta)}
                >
                  <BfRadio value={"business"}>
                    {i18n.t("cb:RentalAgreement.Form.business", "Gewerblich")}
                  </BfRadio>
                  <BfRadio value={"private"}>
                    {i18n.t("cb:RentalAgreement.Form.private", "Privat")}
                  </BfRadio>
                </BFRadioGroup>
              )}
            </Field>
          </div>

          <div className={`__field`}>
            <Field name="id" validate={FV.required()}>
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  {...FV.getValidation(meta)}
                  label={i18n.t("cb:RentalAgreement.Form.id", "ID") + "*"}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="displayName" validate={FV.required()}>
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  {...FV.getValidation(meta)}
                  label={
                    i18n.t("cb:RentalAgreement.Form.displayName", "Name") + "*"
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="note">
              {({ input, meta }) => (
                <BFInput
                  type="textarea"
                  autoResize
                  {...input}
                  {...FV.getValidation(meta)}
                  label={i18n.t("cb:RentalAgreement.Form.note", "Notiz")}
                />
              )}
            </Field>
          </div>
        </>
      )}
    />
  );
};
