import BFAssetChooserSelect from "@/modules/abstract-ui/forms/chooser/BFAssetChooserSelect";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import { useState } from "react";
import { Field } from "react-final-form";
import FormFieldValues from "../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../components/Form/FormStruct/FormStruct";
import FormValidators, {
  FV,
} from "../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import BFInput from "../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../modules/abstract-ui/forms/select/BFSelect";
import { MailIncomingComment } from "../../../../modules/comments-module/Comments";
import CommentEntry from "../../../../modules/comments-module/components/CommentEntry";
import ObjectRelatedSelect from "../../../../modules/structure-components/object/ObjectRelatedSelect";
import PermissionService from "../../../../services/PermissionService";
import ObjectStackingPlan from "../../cashBudget/views/tenants/components/stacking-plan/ObjectStackingPlan";
import {
  EnrichtedRentalUnit,
  RentalAgreement,
} from "../../cashBudget/views/tenants/TenantsInterfaces";
import { ActivityApplicationConstants } from "../ActivityHooks";
import { APActivity } from "../ActivityInterfaces";
import ActivityService from "../ActivityService";
import {
  getAssetLabelByType,
  getAssetSelectOptionsByType,
} from "../ActivityUtils";
import "./APCreationComponent.scss";

interface APCreationComponentProps {
  onClose: () => void;
  onSuccess: (createdId: string) => void;
  onError?: (error: any) => void;
  mail?: MailIncomingComment;
  fromActivity?: APActivity;
  initialData?: Partial<APCreationModel>;
  constants: ActivityApplicationConstants;
}

const calcInitials = (
  props: APCreationComponentProps,
  constants: ActivityApplicationConstants
) => {
  if (props.fromActivity) {
    return {
      type: props.fromActivity.data.type,
      name: props.fromActivity.data.displayName,
      entity: props.fromActivity.data.entity,
      shortDescription: props.fromActivity.data.shortDescription,
      objectId: props.fromActivity.data.objectId,
      relations: props.fromActivity.data.relations,
      linkedAsset: props.fromActivity.data.linkedAsset,
    };
  } else {
    const typeOptions = UnitStruct.getUnitSelectOptions(
      PermissionService.hasBusinessUnitRole(
        constants?.permissionPrefix + "createActivities",
        undefined,
        false
      ) || []
    );
    return typeOptions.length === 1
      ? {
          type: typeOptions[0].value,
          linkedAsset: props.constants.fields?.linkedAsset
            ? { assetType: props.constants.fields?.linkedAsset, assetId: null }
            : undefined,
          ...(props.initialData || {}),
        }
      : {
          linkedAsset: props.constants.fields?.linkedAsset
            ? { assetType: props.constants.fields?.linkedAsset, assetId: null }
            : undefined,
          ...(props.initialData || {}),
        };
  }
};

export interface APCreationModel {
  type: string;
  name: string;
  entity: string;
  objectId: string;
  relations: { assetId: string; assetType: string }[];
  shortDescription: string;
  linkedAsset?: {
    assetType: string;
    assetId: string;
  };
}
const APCreationComponent = (props: APCreationComponentProps) => {
  const constants = props.constants;
  const [initial] = useState(calcInitials(props, constants));
  const typeOptions = UnitStruct.getUnitSelectOptions(
    PermissionService.hasBusinessUnitRole(
      constants?.permissionPrefix + "createActivities",
      undefined,
      false
    ) || []
  );

  const renderMailView = () => {
    return (
      <div className="mail-container">
        <CommentEntry comment={props.mail} allowFullscreen={false} />
      </div>
    );
  };

  return (
    <FormStruct
      initialValues={initial}
      className={classNames("ap-create-activity", {
        "mail-split-view": !!props.mail,
      })}
      title={
        props.mail
          ? i18n.t(
              "apTemplate:Activity.CreationFromComment.Title",
              "Aktivität aus E-Mail erstellen",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )
          : i18n.t(
              "apTemplate:Activity.Creation.Title",
              "Aktivität erstellen",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )
      }
      description={
        props.mail
          ? i18n.t(
              "apTemplate:Activity.CreationFromComment.Description",
              "Bitte füllen Sie die Daten für die neue Aktivität aus. Die E-Mail wird automatisch zu der Aktivität zugeordnet. Nach dem Erstelleb können Sie weitere Daten, Dateien und Kommunikation hinzufügen.",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )
          : i18n.t(
              "apTemplate:Activity.Creation.Description",
              "Bitte füllen Sie die Daten für die neue Aktivität aus. Nach dem Erstellen können Sie weitere Daten, Dateien und Kommunikation hinzufügen.",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )
      }
      onAbort={props.onClose}
      onSubmit={async (values) => {
        try {
          const result = (await ActivityService.createActivity(
            constants?.serviceUrl,
            values,
            props.mail?._id,
            {
              activityCostCenter: values.activityCostCenter || undefined,
              contractId: values.contractId || undefined,
            }
          )) as APActivity;
          props.onSuccess(result?._id);
          props.onClose();
        } catch (err) {
          props.onError?.(err);
        }
      }}
      validate={validate(constants)}
      render={({ form }) => (
        <>
          <div className="fields-container">
            {typeOptions.length !== 1 && (
              <div className={`__field`}>
                <Field name="type">
                  {({ input, meta }) => (
                    <BFSelect
                      {...input}
                      label={`${i18n.t(
                        "apTemplate:Activity.Creation.Fields.type",
                        "Bereich"
                      )}*`}
                      validation={
                        meta.error && meta.touched
                          ? { level: "error", message: meta.error }
                          : undefined
                      }
                      data={typeOptions}
                      onChange={(value) => {
                        input.onChange(value);
                        form.mutators.setValue("entity", null);
                        form.mutators.setValue("objectId", null);
                        form.mutators.setValue("relations", []);
                        if (constants.fields?.linkedAsset) {
                          form.mutators.setValue("linkedAsset.assetId", null);
                        }
                      }}
                    />
                  )}
                </Field>
              </div>
            )}
            <div className={`__field`}>
              <FormFieldValues names={["type"]}>
                {([type]) => (
                  <Field name="entity">
                    {({ input, meta }) => (
                      <BFSelect
                        {...input}
                        disabled={!type}
                        label={`${i18n.t(
                          "apTemplate:Activity.Creation.Fields.entity",
                          "Gesellschaft",
                          {
                            ns: [constants?.assetType, "apTemplate"],
                          }
                        )}*`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                        data={OrgaStruct.getEntitySelectOptions(type)}
                        onChange={(value) => {
                          input.onChange(value);
                          form.mutators.setValue("objectId", null);
                          form.mutators.setValue("relations", []);
                          if (constants.fields?.linkedAsset) {
                            form.mutators.setValue("linkedAsset.assetId", null);
                          }
                        }}
                      />
                    )}
                  </Field>
                )}
              </FormFieldValues>
            </div>
            <div className={`__field`}>
              <FormFieldValues names={["type", "entity"]}>
                {([type, entity]) => (
                  <Field
                    name="objectId"
                    validate={
                      constants?.fields?.objectIdMandatory
                        ? FormValidators.required()
                        : undefined
                    }
                  >
                    {({ input, meta }) => (
                      <BFSelect
                        {...input}
                        disabled={!type}
                        label={`${i18n.t(
                          "apTemplate:Activity.Creation.Fields.objectId",
                          "Objekt",
                          {
                            ns: [constants?.assetType, "apTemplate"],
                          }
                        )}${constants?.fields?.objectIdMandatory ? "*" : ""}`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                        data={OrgaStruct.getObjectSelectOptions(entity, [type])}
                        onChange={(value) => {
                          input.onChange(value);
                          if (!entity) {
                            const entityObj = OrgaStruct.getEntity(
                              OrgaStruct.getObject(value)?.entityId
                            );
                            form.mutators.setValue("entity", entityObj._id);
                          }

                          form.mutators.setValue("relations", []);
                          if (constants.fields?.linkedAsset) {
                            form.mutators.setValue("linkedAsset.assetId", null);
                          }
                        }}
                      />
                    )}
                  </Field>
                )}
              </FormFieldValues>
            </div>

            {constants?.fields?.linkedAsset && (
              <div className={`__field`}>
                <FormFieldValues names={["objectId"]}>
                  {([objectId]) => (
                    <Field
                      name="linkedAsset.assetId"
                      validate={FormValidators.required()}
                    >
                      {({ input, meta }) => (
                        <BFAssetChooserSelect
                          label={getAssetLabelByType(
                            constants.fields.linkedAsset
                          )}
                          {...FV.getValidation(meta)}
                          {...input}
                          assetType={constants?.fields?.linkedAsset}
                          disabled={!objectId}
                          matchQuery={MQ.eq("data.objectId", objectId)}
                          getOption={(node: RentalAgreement) =>
                            getAssetSelectOptionsByType(
                              constants.fields.linkedAsset,
                              node
                            )
                          }
                        />
                      )}
                    </Field>
                  )}
                </FormFieldValues>
              </div>
            )}

            <div className={`__field`}>
              <FormFieldValues names={["objectId"]}>
                {([objectId]) => (
                  <Field name="relations">
                    {({ input, meta }) => (
                      <div>
                        {constants.fields?.stackingPlan &&
                          constants?.fields?.linkedAsset !==
                            AssetTypes.Rental.RentalAgreement &&
                          objectId && (
                            <div className={`stacking-plan-container`}>
                              <ObjectStackingPlan
                                objectId={objectId}
                                selectMode="multiple"
                                selected={
                                  input.value?.map(({ assetId }) => assetId) ||
                                  []
                                }
                                onSelect={(entries: EnrichtedRentalUnit[]) => {
                                  input.onChange(
                                    entries.map((entry) => ({
                                      assetType: AssetTypes.Rental.RentalUnit,
                                      assetId: entry._id,
                                    }))
                                  );
                                }}
                              />
                            </div>
                          )}
                        {constants.fields?.stackingPlan &&
                          constants?.fields?.linkedAsset ===
                            AssetTypes.Rental.RentalAgreement &&
                          objectId && (
                            <div className={`stacking-plan-container`}>
                              <Field name={"linkedAsset.assetId"}>
                                {(linkedAsset) => (
                                  <ObjectStackingPlan
                                    objectId={objectId}
                                    selectMode="single"
                                    selected={
                                      linkedAsset.value
                                        ? [linkedAsset.value]
                                        : []
                                    }
                                    onSelect={(entry: EnrichtedRentalUnit) => {
                                      if (entry.data.agreement?._id) {
                                        linkedAsset.input.onChange(
                                          entry.data.agreement?._id
                                        );
                                      } else {
                                        const val = linkedAsset.input.value;
                                        linkedAsset.input.onChange(null);
                                        setTimeout(() => {
                                          linkedAsset.input.onChange(val);
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          )}

                        <ObjectRelatedSelect
                          multiple={true}
                          block
                          appearance="bf"
                          disabled={!objectId}
                          objectId={objectId}
                          identifier="ap-creation-activity-assignment"
                          value={input.value}
                          cleanable
                          onChange={input.onChange}
                          label={`${i18n.t(
                            "apTemplate:Activity.Creation.Fields.relations",
                            "Zuordnung",
                            {
                              ns: [constants?.assetType, "apTemplate"],
                            }
                          )}`}
                          assets={
                            // load relation types by config, otherwise use default
                            constants.fields?.relationTypes || [
                              AssetTypes.Rental.RentalAgreement,
                              `${AssetTypes.Rental.RentalAgreement}_old`,
                              AssetTypes.Portfolio.TechnicalUnit,
                              AssetTypes.Portfolio.SupplyUnit,
                            ]
                          }
                        />
                      </div>
                    )}
                  </Field>
                )}
              </FormFieldValues>
            </div>
            <hr />
            {constants?.fields?.activityCostCenter && (
              <div className={`__field`}>
                <Field
                  name="activityCostCenter"
                  validate={FormValidators.compose(
                    FormValidators.required(),
                    FormValidators.min(1),
                    FormValidators.max(100)
                  )}
                >
                  {({ input, meta }) => (
                    <BFInput
                      {...input}
                      label={`${i18n.t(
                        "apTemplate:Activity.Creation.Fields.activityCostCenter",
                        "Kostenstelle",
                        {
                          ns: [constants?.assetType, "apTemplate"],
                        }
                      )}*`}
                      validation={
                        meta.error && meta.touched
                          ? {
                              level: "error",
                              message: meta.error,
                            }
                          : undefined
                      }
                    />
                  )}
                </Field>
              </div>
            )}
            {constants?.fields?.contractData && (
              <div className={`__field`}>
                <Field
                  name="contractId"
                  validate={FormValidators.compose(
                    FormValidators.required(),
                    FormValidators.min(1),
                    FormValidators.max(100)
                  )}
                >
                  {({ input, meta }) => (
                    <BFInput
                      {...input}
                      label={`${i18n.t(
                        "apTemplate:Activity.Creation.Fields.contractId",
                        "Vertragskonto",
                        {
                          ns: [constants?.assetType, "apTemplate"],
                        }
                      )}*`}
                      validation={
                        meta.error && meta.touched
                          ? {
                              level: "error",
                              message: meta.error,
                            }
                          : undefined
                      }
                    />
                  )}
                </Field>
              </div>
            )}
            <div className={`__field`}>
              <Field name="name">
                {({ input, meta }) => (
                  <BFInput
                    {...input}
                    label={`${i18n.t(
                      "apTemplate:Activity.Creation.Fields.title",
                      "Titel",
                      {
                        ns: [constants?.assetType, "apTemplate"],
                      }
                    )}*`}
                    validation={
                      meta.error && meta.touched
                        ? {
                            level: "error",
                            message: meta.error,
                          }
                        : undefined
                    }
                  />
                )}
              </Field>
            </div>
            <div className={`__field`}>
              <Field name="shortDescription">
                {({ input, meta }) => (
                  <BFInput
                    {...input}
                    type="textarea"
                    label={`${i18n.t(
                      "apTemplate:Activity.Creation.Fields.shortDescription",
                      "Beschreibung",
                      {
                        ns: [constants?.assetType, "apTemplate"],
                      }
                    )}`}
                    validation={
                      meta.error && meta.touched
                        ? {
                            level: "error",
                            message: meta.error,
                          }
                        : undefined
                    }
                  />
                )}
              </Field>
            </div>
          </div>
          {props.mail && renderMailView()}
        </>
      )}
    />
  );
};

export default APCreationComponent;

const validate = (constants: ActivityApplicationConstants) => (data) => {
  const errors = {};

  if (!data.type) {
    errors["type"] = i18n.t("Global.Label.Required", "Pflichtfeld");
  }
  if (!data.entity) {
    errors["entity"] = i18n.t("Global.Label.Required", "Pflichtfeld");
  }
  if (constants?.fields?.objectIdMandatory) {
    if (!data.objectId) {
      errors["objectId"] = i18n.t("Global.Label.Required", "Pflichtfeld");
    }
  }
  if (!data.name) {
    errors["name"] = i18n.t("Global.Label.Required", "Pflichtfeld");
  }
  if (0.0) {
    errors["name"] = i18n.t("Global.Label.max120", "Maximal 120 Zeichen");
  }

  return errors;
};
