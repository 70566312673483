import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { Drawer } from "rsuite";
import FormFieldValues from "../../../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import LoadPage from "../../../../../../components/LoadPage/LoadPage";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import DebugDataComponent from "../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../i18n";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFMessage from "../../../../../../modules/abstract-ui/general/Message/BFMessage";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import BFGanttChart from "../../../../../../modules/gantt-chart/BFGanttChart";
import { GanttTask } from "../../../../../../modules/gantt-chart/BFGanttChart.interface";
import { Task } from "../../../../../../modules/gantt-chart/lib";
import PlanTable, {
  PlanTableEntry,
  PlanTableValue,
} from "../../../../../../modules/plan-table/PlanTable";
import { useStatisticQuery } from "../../../../../../redux/hooks";
import DataBusDefaults from "../../../../../../services/DataBusDefaults";
import { hasValue } from "../../../../../../utils/Helpers";
import { APStatusTags } from "../../../../../AppConfigInterfaces";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import ActivityService from "../../../ActivityService";
import { useActivityStruct } from "../../../ActivityStructContext";
import APTaskConstructionDiariesEntries from "../construction-diaries/APTaskConstructionDiariesEntries";
import {
  TEMPLATE_TYPE_BUDGET_GROUP,
  getAllChildsOfProjectScheduleGroup,
} from "./APBugetUtils";
import APProjectBudgetDrawer from "./APProjectBudgetDrawer";
import "./APProjectBudgetForm.scss";
import {
  APProjectBudget,
  ProjectBudgetGroup,
  ProjectBudgetPlanEntry,
  ProjectScheduleEntry,
  ProjectScheduleGroup,
} from "./APProjectBudgetInterfaces";
import APScheduleTaskProgress, {
  APSchduleTaskProgressHeader,
} from "./APScheduleTaskProgress";

interface APBudgetFormProps {
  readonly?: boolean;
  activity: APActivity;
  budget?: APProjectBudget;
  currentBudget?: APProjectBudget;
  showActualData?: boolean;
  onSubmit?: (result: APProjectBudget) => void;

  hideSchedule?: boolean;
  hideBudget?: boolean;
}

const convertScheduleDataToForm = (
  scheduleEntries: ProjectScheduleEntry[],
  scheduleGroups: ProjectScheduleGroup[]
) => {
  const tasks: GanttTask[] = [];

  scheduleGroups?.forEach((group) => {
    tasks.push({
      id: group.id,
      name: group.name,
      progress: scheduleEntries?.find((e) => e.groupId === group.id) ? 100 : 0,
      start: _.min([new Date(group.fromDate), new Date(group.toDate)]),
      end: _.max([new Date(group.fromDate), new Date(group.toDate)]),
      parent: group.parentId,
    });
  });
  return tasks;
};
const convertScheduleFormToData = (tasks: GanttTask[]) => {
  const scheduleGroups: ProjectScheduleGroup[] = [];

  tasks.forEach((task) => {
    scheduleGroups.push({
      id: task.id,
      fromDate: task.start,
      name: task.name,
      toDate: task.end,
      parentId: task.parent,
    });
  });

  return scheduleGroups;
};

const convertInitialValues = (budget?: APProjectBudget) => {
  return {
    budget: {
      entries:
        budget?.data.entries.map((e) => ({
          date: e.date,
          values: Object.fromEntries(e.values.map((v) => [v.groupId, v.value])),
        })) || [],
      groups: budget?.data.groups || [],
      from: moment(budget?.data.fromDate).utc(true).toDate(),
      to: moment(budget?.data.toDate).utc(true).toDate(),
    },
    schedule: budget
      ? convertScheduleDataToForm(
          budget.data.scheduleEntries,
          budget.data.scheduleGroups
        )
      : [],
  } as FormValues;
};
const convertToSubmit = (values: FormValues) => {
  const result: {
    scheduleGroups: ProjectScheduleGroup[];
    entries: ProjectBudgetPlanEntry[];
    groups: ProjectBudgetGroup[];
  } = {
    scheduleGroups: convertScheduleFormToData(values.schedule),
    entries: [],
    groups: values.budget.groups,
  };

  values.budget.entries.map((entry) => {
    result.entries.push({
      date: entry.date,
      values: Object.entries(entry.values).map(([groupId, value]) => {
        return {
          groupId,
          value,
        };
      }),
    });
  });

  return result;
};
interface FormValues {
  budget: PlanTableValue;
  showActualData?: boolean;
  schedule: GanttTask[];
}

const convertActualData = (
  data: {
    _id: { month: number; year: number; categoryId: string };
    value: number;
  }[]
) => {
  const actualValue: PlanTableEntry[] = [];

  data.forEach((entry) => {
    const date = moment()
      .year(entry._id.year)
      .month(entry._id.month)
      .utc(true)
      .startOf("month");

    const actualEntry = actualValue.find((e) =>
      moment(e.date).isSame(date, "month")
    );

    if (!actualEntry) {
      actualValue.push({
        date: date.toDate(),
        values: {
          [entry._id.categoryId]: entry.value,
        },
      });
    } else {
      actualEntry.values[entry._id.categoryId] = entry.value;
    }
  });
  return actualValue;
};

const APProjectBudgetForm = (props: APBudgetFormProps) => {
  const [drawerActive, setDrawerActive] = useState<{
    id: string;
    type: "group" | "subGroup";
    date: Date;
    value: number;
  }>(null);
  const activityStruct = useActivityStruct();
  const constants = useActivityConstants();
  const [iniital, setInitial] = useState(convertInitialValues(props.budget));
  const [convertedActual, setActual] = useState<PlanTableEntry[]>([]);
  const actualData = useStatisticQuery<
    {
      _id: { month: number; year: number; categoryId: string };
      value: number;
    }[]
  >("PROJECT_BOOKED_DETAIL", 1, {
    projectId: props.activity._id,
  });

  useEffect(() => {
    if (actualData.data) {
      setActual(convertActualData(actualData.data));
    }
  }, [actualData.data]);

  useEffect(() => {
    setInitial(convertInitialValues(props.budget));
  }, [props.budget]);

  const status = activityStruct.getStatus(
    props.activity.data.type,
    props.activity.data.status
  );

  const isInApproval = activityStruct
    .getStatus(props.activity.data.type, props.activity.data.status)
    ?.tags.includes(APStatusTags.PROJECT_BUDGET_IN_APPROVAL);

  const isReadOnly = isInApproval || props.readonly;
  if (actualData.loading) {
    return <LoadPage />;
  }
  const renderDrawer = (drawerActive: {
    id: string;
    type: "group" | "subGroup";
    date: Date;
    value: number;
  }) => {
    if (!drawerActive) {
      return null;
    }
    let title, subTitle, ids;
    if (drawerActive.type === "group") {
      const group = props.budget?.data.groups.find(
        (e) => e.id === drawerActive.id
      );
      title = group?.name;
      ids = group?.children.map((e) => e.id);
    }
    if (drawerActive.type === "subGroup") {
      const groupsFlattened =
        props.budget?.data.groups
          .map((group) => [
            { ...group, parent: null },
            ...(group.children?.map((e) => ({ ...e, parent: group.id })) || []),
          ])
          .flat() || [];
      const group = groupsFlattened.find((e) => e.id === drawerActive.id);
      title = group?.name;
      subTitle = groupsFlattened.find((e) => e.id === group?.parent)?.name;
      ids = [group.id];
    }

    return (
      <APProjectBudgetDrawer
        activity={props.activity}
        ids={ids}
        title={title}
        subTitle={subTitle}
        date={drawerActive.date}
        sum={drawerActive.value}
      />
    );
  };
  return (
    <FormStruct
      className={`ap-project-budget-form`}
      abortBtnProps={{
        appearance: "default",
      }}
      notModal
      onSubmit={async (values: FormValues) => {
        const converted = convertToSubmit(values);
        const from = values.budget.from;
        const to = values.budget.to;
        // const dates = converted.entries?.map((e) => e.date) || [];
        // const minDate = _.min([...dates, props.budget?.data.fromDate]);
        // const maxDate = _.max([...dates, props.budget?.data.toDate]);
        const draft = await ActivityService.saveProjectBudgetDraft(
          constants.serviceUrl,
          props.activity._id,
          converted.entries,
          converted.groups,
          from,
          to,
          converted.scheduleGroups
        );

        props.onSubmit(draft);

        DataBusDefaults.toast({
          type: "success",
          text: i18n.t(
            "apTemplate:Activity.Budget.BudgetSaved",
            "Budget wurde gespeichert"
          ),
        });
      }}
      cancelText={i18n.t("apTemplate:Activity.Budget.Cancel", "Abbrechen")}
      submitText={
        props.budget
          ? i18n.t("apTemplate:Activity.Budget.Save", "Budgetplan speichern")
          : i18n.t("apTemplate:Activity.Budget.Create", "Budgetplan erstellen")
      }
      ignoreSubmitOnEnter
      noPadding
      actionsOnChange
      usePrompt
      initialValues={iniital}
      onAbort={() => {
        setInitial(convertInitialValues(props.budget));
      }}
      render={(formProps) => (
        <>
          <DebugDataComponent
            data={{
              activity: props.activity,
              budgetPlan: props.budget,
              currentBudget: props.currentBudget,
              actualData: props.showActualData ? convertedActual : null,
            }}
          />
          {!props.readonly && (
            <BFMessage
              type="info"
              closable
              text={
                isInApproval
                  ? i18n.t(
                      "apTemplate:Activity.Budget.InApproval",
                      "Das Budget befindet sich aktuell in der Genehmigung. Es besteht keine Möglichkeit das Budget zu bearbeiten, bis die Genehmigung abgeschlossen ist."
                    )
                  : i18n.t(
                      "apTemplate:Activity.Budget.BudgetInPlanningText",
                      "Das Budget befindet sich in der Planungsphase. Sie haben die Möglichkeit Änderungen im Plan durchzuführen ",
                      {
                        ns: ["apTemplate"],
                      }
                    )
              }
            />
          )}
          {!props.readonly &&
            !status?.tags?.includes(APStatusTags.PROJECT_BUDGET_IN_APPROVAL) &&
            props.budget && (
              <div className={`send-to-approval`}>
                {props.currentBudget && (
                  <Field name={"budget"}>
                    {({ input, meta }) => (
                      <BFButton
                        onClick={() => {
                          ModalManager.show({
                            size: "md",
                            content: (
                              <div>
                                {i18n.t(
                                  "apTemplate:Activity.Budget.ResetDescription",
                                  "Wollen Sie die Daten der Budgetplanung auf das aktuell genehmigte Budget zurücksetzen? Die Änderungen werden nicht sofort gespeichert, sondern müssen weiterhin über das Speichern der Planung nochmal entgültig gespeichert werden."
                                )}
                              </div>
                            ),
                            buttons: [
                              {
                                text: i18n.t("Global.Buttons.cancel"),
                                closeOnClick: true,
                                appearance: "outline",
                              },
                              {
                                text: i18n.t(
                                  "apTemplate:Activity.Budget.ResetToCurrentBudgetWithGroupChange",
                                  "Budget & Gruppen zurücksetzen"
                                ),
                                closeOnClick: true,
                                onClick: () => {
                                  const intitialResetValue =
                                    convertInitialValues(props.currentBudget);
                                  input.onChange(intitialResetValue.budget);
                                },
                                appearance: "outline",
                              },
                              {
                                text: i18n.t(
                                  "apTemplate:Activity.Budget.ResetToCurrentBudgetWithoutGroupChange",
                                  "Nur Budget zurücksetzen"
                                ),
                                closeOnClick: true,
                                onClick: () => {
                                  const intitialResetValue =
                                    convertInitialValues(props.currentBudget);
                                  const { groups, ...toSet } =
                                    intitialResetValue.budget;
                                  input.onChange({
                                    ...toSet,
                                    groups: input.value.groups,
                                  });
                                },
                                appearance: "primary",
                              },
                            ],
                          });
                        }}
                      >
                        {i18n.t(
                          "apTemplate:Activity.Budget.ResetToCurrentBudget",
                          "Budget zurücksetzen"
                        )}
                      </BFButton>
                    )}
                  </Field>
                )}
                <div className={`fill`} />
                <BFButton
                  disabled={
                    formProps.dirty ||
                    (props.budget.data.entries || []).length === 0
                  }
                  onClick={async () => {
                    const draft =
                      await ActivityService.publishProjectBudgetDraft(
                        constants.serviceUrl,
                        props.activity._id,
                        props.budget._id
                      );
                    props.onSubmit(draft);

                    DataBusDefaults.toast({
                      type: "success",
                      text: i18n.t(
                        "apTemplate:Activity.Budget.SentToApprovalSuccess",
                        "Budget wurde zur Genehmigung gesendet"
                      ),
                    });
                  }}
                >
                  {i18n.t(
                    "apTemplate:Activity.Budget.SendToApproval",
                    "Zur Genehmigung senden"
                  )}
                </BFButton>
              </div>
            )}

          {!props.hideBudget && (
            <div className={`budget-plan`}>
              <FormFieldValues names={["from", "until"]}>
                {([from, until]) => (
                  <Field name={"budget"}>
                    {({ input, meta }) => (
                      <PlanTable
                        planType="timespanAndGroups"
                        onDoubleClick={(val, index) => {
                          setDrawerActive(val);
                        }}
                        // startBudget={50000}
                        actualValue={
                          props.showActualData ? convertedActual || [] : null
                        }
                        type="month"
                        readonly={isReadOnly}
                        fixedPositionIds={
                          props.currentBudget?.data.groups
                            .map((e) => [...e.children, e])
                            .flat()
                            .map((e) => e.id) || []
                        }
                        templateData={{
                          templateType: TEMPLATE_TYPE_BUDGET_GROUP,
                          type: props.activity.data.type,
                        }}
                        value={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                )}
              </FormFieldValues>

              <Drawer
                size="md"
                open={hasValue(drawerActive)}
                onClose={() => setDrawerActive(null)}
                className={`budget-plan-drawer`}
              >
                {drawerActive && renderDrawer(drawerActive)}
              </Drawer>
            </div>
          )}

          {!props.hideSchedule && (
            <Field name={"schedule"}>
              {({ input, meta }) => (
                <div className={`gantt-card __card margin-top-20`}>
                  <div className={`card-head`}>
                    <div className={`title`}>
                      {i18n.t(
                        "apTemplate:Activity.Budget.Schedule",
                        "Aufgabenplanung"
                      )}
                    </div>

                    <div className={`fill`} />
                    {!props.readonly &&
                      !status?.tags?.includes(
                        APStatusTags.PROJECT_BUDGET_IN_APPROVAL
                      ) &&
                      props.budget &&
                      props.currentBudget && (
                        <BFButton
                          onClick={() => {
                            ModalManager.confirm({
                              message: i18n.t(
                                "apTemplate:Activity.Budget.ResetScheduleDescription",
                                "Wollen Sie die Daten der Aufgabenplanung auf das aktuell genehmigte Budget zurücksetzen? Die Änderungen werden nicht sofort gespeichert, sondern müssen weiterhin über das Speichern der Planung nochmal entgültig gespeichert werden."
                              ),
                              confirmButtonText: i18n.t(
                                "apTemplate:Activity.Budget.ResetSchedule",
                                "Planung zurucksetzen"
                              ),
                              onConfirm: () => {
                                const intitialResetValue = convertInitialValues(
                                  props.currentBudget
                                );
                                input.onChange(intitialResetValue.schedule);
                              },
                            });
                          }}
                        >
                          {i18n.t(
                            "apTemplate:Activity.Budget.ResetSchedule",
                            "Planung zurucksetzen"
                          )}
                        </BFButton>
                      )}
                  </div>
                  <BFGanttChart
                    readonly={isReadOnly}
                    value={input.value || []}
                    onChange={input.onChange}
                    fixedTasks={props.currentBudget?.data.scheduleGroups.map(
                      (e) => e.id
                    )}
                    // progressChange={"manual"}
                    additionalColumn={
                      isReadOnly &&
                      !status?.tags?.includes(
                        APStatusTags.PROJECT_BUDGET_IN_APPROVAL
                      ) &&
                      props.budget
                        ? {
                            renderHeader: () => <APSchduleTaskProgressHeader />,
                            renderColumn: (task: Task) => (
                              <div className={`project-budget-progress-column`}>
                                <div className={`progress`}>
                                  <APScheduleTaskProgress
                                    activityId={props.activity._id}
                                    task={task}
                                    scheduleProgressEntry={props.budget?.data.scheduleEntries?.find(
                                      (e) => e.groupId === task.id
                                    )}
                                  />
                                </div>
                                <div className={`gallery-button`}>
                                  <BFButton
                                    appearance="link"
                                    size="xs"
                                    onClick={() => {
                                      const groups = [
                                        task.id,
                                        ...getAllChildsOfProjectScheduleGroup(
                                          task.id,
                                          props.budget.data.scheduleGroups
                                        ).map((e) => e.id),
                                      ];

                                      ModalManager.show({
                                        size: "md",
                                        closable: true,
                                        backdrop: true,
                                        headerCloseButton: true,

                                        title: task.name,
                                        content: (state, setState, close) => (
                                          <APTaskConstructionDiariesEntries
                                            groupIds={groups}
                                            projectId={props.activity._id}
                                            onClose={close}
                                          />
                                        ),
                                      });
                                    }}
                                  >
                                    <BfIcon
                                      type="light"
                                      data="picture-stack-landscape"
                                      size="xxs"
                                    />
                                  </BFButton>
                                </div>
                              </div>
                            ),
                          }
                        : undefined
                    }
                  />
                </div>
              )}
            </Field>
          )}
        </>
      )}
    />
  );
};

export default APProjectBudgetForm;
