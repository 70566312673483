import { Field } from "react-final-form";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";
import { Application } from "../../model/db/Application";
import BFOrderList from "../../modules/abstract-ui/data/order-list/BFOrderList";
import Customization from "../../redux/actions/customization/customization-actions";
import FormStruct from "../Form/FormStruct/FormStruct";
import "./AppDrawerOrderList.scss";

export const APP_DRAWER_SORT = "app-drawer-sort";
interface AppDrawerOrderListProps {
  apps: Application[];
  onClose: () => void;
  onSuccess: (orderList: string[]) => void;
}
const AppDrawerOrderList = (props: AppDrawerOrderListProps) => {
  const dispatch = useDispatch();

  return (
    <FormStruct
      className={`app-drawer-order-list`}
      initialValues={{
        apps: props.apps,
      }}
      title={i18n.t("AppDrawer.OrderList.Title", "Apps sortieren")}
      description={i18n.t(
        "AppDrawer.OrderList.Description",
        "Sortieren Sie die Apps nach Ihren Wünschen."
      )}
      submitText={i18n.t("AppDrawer.OrderList.Submit", "Reihenfolge speichern")}
      onAbort={props.onClose}
      onSubmit={async (values) => {
        const appSort = values.apps.map((app) => app._id);
        dispatch(Customization.setFlexValue(APP_DRAWER_SORT, appSort));
        props.onClose();
        props.onSuccess(appSort);
      }}
      render={({ form }) => (
        <>
          <Field name="apps">
            {({ input, meta }) => (
              <BFOrderList
                idSelector="_id"
                value={input.value}
                onChange={input.onChange}
                renderValue={(app: Application) => (
                  <div className={`app-drawer-order-list-app-entry`}>
                    <div
                      className="application-logo"
                      style={{
                        backgroundImage: `url(${
                          app.logo ? app.logo.drawer : ""
                        })`,
                      }}
                    />
                    <div className={`name`}>{app.displayName}</div>
                  </div>
                )}
              />
            )}
          </Field>
        </>
      )}
    />
  );
};

export default AppDrawerOrderList;
