import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import BFDropdown from "@/modules/abstract-ui/general/Dropdown/BFDropdown";
import { DropdownItem } from "@/modules/abstract-ui/general/Dropdown/BFDropdownContent";
import BFMessageBox from "@/modules/abstract-ui/general/Message/BFMessageBox";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import CacheService from "@/services/CacheService";
import DataBus from "@/services/DataBus";
import { DataBusSubKeys } from "@/utils/Constants";
import React from "react";
import PersistentSplitPane from "../../../../../../../configurable/data/SplitPane/PersistentSplitPane";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import { DefaultIcons } from "../../../../../../../modules/abstract-ui/icon/DefaultIcons";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import GlobalActions from "../../../../../../../services/GlobalActions";
import { CB_RENTAL_AGREEMENT_LIST } from "../../CBTenantsConst";
import { EnrichtedRentalUnit, RentalAgreement } from "../../TenantsInterfaces";
import ObjectStackingPlan from "../stacking-plan/ObjectStackingPlan";
import CBAgreementAutomatic from "./agreement-parts/CBAgreementAutomatic";
import CBAgreementContractBasedata from "./agreement-parts/CBAgreementContractBasedata";
import CBAgreementCopayment from "./agreement-parts/CBAgreementCopayment";
import CBAgreementDeposit from "./agreement-parts/CBAgreementDeposit";
import CBAgreementGraduatedRent from "./agreement-parts/CBAgreementGraduatedRent";
import CBAgreementIndex from "./agreement-parts/CBAgreementIndex";
import { openAgreementMergeModal } from "./agreement-parts/CBAgreementMergeModal";
import CBAgreementOption from "./agreement-parts/CBAgreementOption";
import CBAgreementPaymentInfo from "./agreement-parts/CBAgreementPaymentInfo";
import CBAgreementRentalUnits from "./agreement-parts/CBAgreementRentalUnits";
import CBAgreementRentDuration from "./agreement-parts/CBAgreementRentDuration";
import CBAgreementTenant from "./agreement-parts/CBAgreementTenant";
import "./CBRentalAgreementDetailsPage.scss";
import {
  openModalAddDebitRunStop,
  openModalAddDunningStop,
  openModalRemoveDebitRunStop,
  openModalRemoveDunningStop,
} from "./CBRentalAgreementHelper";

interface CBRentalAgreementDetailsProps {
  goBack?: () => void;
  rentalAgreement: RentalAgreement;
  hideBackButton?: boolean;
  hideStackingPlan?: boolean;
  hideActions?: boolean;
  kind: ObjectKind;
}
export const CBRentalAgreementDetailsPage = (
  props: CBRentalAgreementDetailsProps
) => {
  const object = OrgaStruct.getObject(props.rentalAgreement.data.objectId);
  const chartId = React.useRef<string>(null);

  const comp = (
    <>
      <div className={`page-content`}>
        <div className={`header`}>
          {!props.hideBackButton && (
            <BFButton
              className={`back-button`}
              appearance={"clear-on-white"}
              icon={{ size: "xs", ...DefaultIcons.BACK }}
              onClick={props.goBack}
            />
          )}
          <div className={`title`}>
            {OrgaStruct.isImportedObject(
              props.rentalAgreement.data.objectId
            ) && (
              <div className={`margin-top-10`}>
                <BFMessageBox type="info" inline small>
                  {i18n.t(
                    "cb:RentalAgreement.ImportedObject",
                    "Importierter Mietvertrag aus Domus"
                  )}
                </BFMessageBox>
              </div>
            )}
            <div className={`id`}>
              {i18n.t("cb:RentalAgreement.DetailsTitle", "Mietvertrag")}{" "}
              {props.rentalAgreement.data.id}
              <DebugDataComponent data={props.rentalAgreement} />
            </div>
            <div className={`name`}>
              {props.rentalAgreement.data.displayName}
            </div>
            <div className={`object`}>
              <BFDetailsButton
                appearance="link"
                size="xs"
                data={{
                  assetType: AssetTypes.Portfolio.Object,
                  assetId: props.rentalAgreement.data.objectId,
                  type: props.rentalAgreement.data.type,
                }}
              >
                {OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.id}{" "}
                -{" "}
                {
                  OrgaStruct.getObject(props.rentalAgreement.data.objectId)
                    ?.displayName
                }
              </BFDetailsButton>
              <div className={`entity`}>
                {
                  OrgaStruct.getEntity(props.rentalAgreement.data.entity)
                    ?.displayName
                }
              </div>
            </div>
          </div>
          <div className={`actions padding-right-10`}>
            <BFDropdown
              toggleAs={(bProps) => (
                <BFButton appearance="outline" size="sm" {...bProps} />
              )}
              label={i18n.t("cb:RentalAgreement.Action.ButtonTitle", "Aktion")}
              placement="bottomEnd"
              items={[
                ...((props.rentalAgreement.data.dunning?.stopDunningRun
                  ? [
                      {
                        type: "button",
                        text: i18n.t(
                          "cb:RentalAgreement.Action.RemoveDunningStopButton",
                          "Mahnstop aufheben"
                        ),
                        disabled: object.imported,
                        onSelect: () => {
                          openModalRemoveDunningStop(props.rentalAgreement._id);
                        },
                      },
                    ]
                  : [
                      {
                        type: "button",
                        text: i18n.t(
                          "cb:RentalAgreement.Action.AddDunningStopButton",
                          "Mahnstop einrichten"
                        ),
                        disabled: object.imported,
                        onSelect: () => {
                          openModalAddDunningStop(props.rentalAgreement._id);
                        },
                      },
                    ]) as DropdownItem[]),

                ...((props.rentalAgreement.data.stopDebitRun
                  ? [
                      {
                        type: "button",
                        text: i18n.t(
                          "cb:RentalAgreement.Action.RemoveDebitRunStopButton",
                          "Sollstellungsautomatik aktivieren"
                        ),
                        disabled: object.imported,
                        onSelect: () => {
                          openModalRemoveDebitRunStop(
                            props.rentalAgreement._id
                          );
                        },
                      },
                    ]
                  : [
                      {
                        type: "button",
                        text: i18n.t(
                          "cb:RentalAgreement.Action.AddDebitRunStopButton",
                          "Sollstellungsautomatik deaktivieren"
                        ),
                        disabled: object.imported,
                        onSelect: () => {
                          openModalAddDebitRunStop(props.rentalAgreement._id);
                        },
                      },
                    ]) as DropdownItem[]),

                ...((object.imported
                  ? [
                      {
                        type: "divider",
                      },
                      {
                        type: "button",
                        text: i18n.t(
                          "cb:RentalAgreement.Action.MergeAgreements",
                          "Mietverträge zusammenführen"
                        ),
                        onSelect: () => {
                          openAgreementMergeModal(props.rentalAgreement, () => {
                            DataBusDefaults.reload({
                              identifiers: [CB_RENTAL_AGREEMENT_LIST],
                            });
                            DataBus.emit(DataBusSubKeys.STACKING_PLAN_RELOAD, {
                              objectId: props.rentalAgreement.data.objectId,
                            });
                            CacheService.getData({
                              assetType: AssetTypes.Rental.RentalAgreement,
                              oType: "asset",
                              id: props.rentalAgreement._id,
                              forceReload: true,
                            });
                          });
                        },
                      },
                    ]
                  : []) as DropdownItem[]),
              ]}
            />
          </div>
        </div>
        <div className={`content`}>
          {props.rentalAgreement.data.dunning?.stopDunningRun && (
            <BFMessageBox small type="info" className={`margin-bottom-10`}>
              <div className={`__flex __align-center`}>
                <div className={`__flex-1`}>
                  <div className={`message-box-title`}>
                    {i18n.t(
                      "cb:RentalAgreement.DunningStop.Messagebox.Title",
                      "Mahnstop eingerichtet"
                    )}
                  </div>
                  <div>
                    {i18n.t(
                      "cb:RentalAgreement.DunningStop.Messagebox.Info",
                      "Der Vertrag wird nicht mehr im Mahnlauf berücksichtigt, auch wenn eine Forderung besteht."
                    )}
                  </div>
                </div>

                <div className={`action `}>
                  <BFButton
                    size="xs"
                    appearance="outline"
                    onClick={() => {
                      openModalRemoveDunningStop(props.rentalAgreement._id);
                    }}
                  >
                    {i18n.t(
                      "cb:RentalAgreement.DunningStop.Messagebox.Remove",
                      "Mahnstop aufheben"
                    )}
                  </BFButton>
                </div>
              </div>
            </BFMessageBox>
          )}
          {props.rentalAgreement.data.stopDebitRun && (
            <BFMessageBox small type="info" className={`margin-bottom-10`}>
              <div className={`__flex __align-center`}>
                <div className={`__flex-1`}>
                  <div className={`message-box-title`}>
                    {i18n.t(
                      "cb:RentalAgreement.DebitRunStop.Messagebox.Title",
                      "Automatische Sollstellung deaktiviert"
                    )}
                  </div>
                  <div>
                    {i18n.t(
                      "cb:RentalAgreement.DebitRunStop.Messagebox.Info",
                      "Die automatische Sollstellung wurde deaktiviert. Es werden keine Forderungen mehr automatisch erstellt."
                    )}
                  </div>
                </div>

                <div className={`action `}>
                  <BFButton
                    size="xs"
                    appearance="outline"
                    onClick={() => {
                      openModalRemoveDebitRunStop(props.rentalAgreement._id);
                    }}
                  >
                    {i18n.t(
                      "cb:RentalAgreement.DebitRunStop.Messagebox.Remove",
                      "Wieder aktivieren"
                    )}
                  </BFButton>
                </div>
              </div>
            </BFMessageBox>
          )}
          <div className={`content-base-data`}></div>
          <CBAgreementContractBasedata
            rentalAgreement={props.rentalAgreement}
            editable={!object.imported}
            data={{
              contractType: props.rentalAgreement.data.contractType,
              displayName: props.rentalAgreement.data.displayName,
              id: props.rentalAgreement.data.id,
              note: props.rentalAgreement.data.note,
            }}
          />
          <CBAgreementTenant
            marks={props.rentalAgreement.data.marks?.tenant}
            data={{
              contactOption: props.rentalAgreement.data.contactOption,
              tenant: props.rentalAgreement.data.tenant,
            }}
            editable={!object.imported}
            kind={props.kind}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementRentDuration
            marks={props.rentalAgreement.data.marks?.rentDuration}
            rentalAgreement={props.rentalAgreement}
            editable={!object.imported}
            data={{
              moveIn: props.rentalAgreement.data.moveIn,
              agreementExpiration:
                props.rentalAgreement.data.agreementExpiration,
              moveOut: props.rentalAgreement.data.moveOut,
            }}
          />
          <CBAgreementPaymentInfo
            data={{
              paymentPositions: props.rentalAgreement.data.paymentPositions,
              rentPaymentTimingOptions:
                props.rentalAgreement.data.rentPaymentTimingOptions,
              taxable: props.rentalAgreement.data.taxable
                ? "taxable"
                : "notTaxable",
            }}
            kind={props.kind}
            marks={props.rentalAgreement.data.marks?.paymentInfo}
            objectId={props.rentalAgreement.data.objectId}
            editable={!object.imported}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementDeposit
            editable={!object.imported}
            data={{
              deposit: props.rentalAgreement.data.deposit,
            }}
            marks={props.rentalAgreement.data.marks?.deposit}
            objectId={props.rentalAgreement.data.objectId}
            rentalAgreement={props.rentalAgreement}
          />

          <CBAgreementGraduatedRent
            kind={props.kind}
            marks={props.rentalAgreement.data.marks?.graduatedRent}
            objectId={props.rentalAgreement.data.objectId}
            taxable={props.rentalAgreement.data.taxable}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementRentalUnits
            editable={!object.imported}
            marks={props.rentalAgreement.data.marks?.rentalUnits}
            data={{
              rentalUnits: props.rentalAgreement.data.rentalUnits,
            }}
            rentalAgreement={props.rentalAgreement}
            kind={props.kind}
            objectId={props.rentalAgreement.data.objectId}
            hideStackingplan
          />
          <CBAgreementIndex
            editable={!object.imported}
            marks={props.rentalAgreement.data.marks?.index}
            data={{
              index: props.rentalAgreement.data.index,
            }}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementOption
            editable={!object.imported}
            marks={props.rentalAgreement.data.marks?.option}
            data={{
              option: props.rentalAgreement.data.option,
            }}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementAutomatic
            editable={!object.imported}
            data={{
              automatic: props.rentalAgreement.data.automatic,
            }}
            marks={props.rentalAgreement.data.marks?.automatic}
            rentalAgreement={props.rentalAgreement}
          />
          <CBAgreementCopayment
            editable={!object.imported}
            data={{
              copayment: props.rentalAgreement.data.copayment,
            }}
            marks={props.rentalAgreement.data.marks?.copayment}
            rentalAgreement={props.rentalAgreement}
          />
        </div>
      </div>
    </>
  );
  return (
    <div className="cb-rental-agreement-details">
      {props.hideStackingPlan ? (
        comp
      ) : (
        <PersistentSplitPane
          identifier="cb-rental-agreement-details"
          split="horizontal"
          defaultSize={"50%"}
          maxSize={-200}
          minSize={150}
          primary="first"
          allowResize
          onSizeChange={() => {
            if (chartId.current) {
              DataBusDefaults.chartResized(chartId.current);
            }
          }}
        >
          {props.hideStackingPlan === true ? null : (
            <div className={`object-stacking-plan-wrapper`}>
              <ObjectStackingPlan
                onMounted={(ident, chart) => {
                  chartId.current = ident;
                }}
                objectId={props.rentalAgreement.data.objectId}
                markedUnits={props.rentalAgreement.data.rentalUnits}
                selectMode="single"
                onSelect={(selected: EnrichtedRentalUnit) => {
                  GlobalActions.openDetails(
                    selected.data.rentalStatus === "vacant"
                      ? AssetTypes.Rental.RentalUnit
                      : AssetTypes.Rental.RentalAgreement,
                    selected.data.rentalStatus === "vacant"
                      ? selected._id
                      : selected.data.agreement._id,
                    selected.data.type
                  );
                  //   DataBusDefaults.route({
                  //     route: `/rental-unit/${selected}`,
                  //     stayInApp: true,
                  //   });
                }}
              />
            </div>
          )}
          {comp}
        </PersistentSplitPane>
      )}
    </div>
  );
};

const RentalAgreementSection = (props: {
  title: string;
  onEdit?: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div className={`rental-agreement-section`}>
      <div className={`section-title`}>
        <div className={`__h3`}>{props.title}</div>
        {props.onEdit && (
          <BFButton
            noPadding
            appearance="link"
            onClick={props.onEdit}
            size="sm"
          >
            {i18n.t("Global.Buttons.Customize", "Anpassen")}
          </BFButton>
        )}
      </div>
      <div className={`section-content`}>{props.children}</div>
    </div>
  );
};
