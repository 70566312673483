import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import AccountingService from "@/apps/tatar/accounting/AccountingService";
import AccountSelection from "@/apps/tatar/accounting/components/account-selection/AccountSelection";
import {
  AccountingAccount,
  AccountingBookingFormValue,
  AccountType,
} from "@/apps/tatar/accounting/interfaces/account.interface";
import CBRentalAgreementOpenDebitPositions, {
  OpenDebitPositionValue,
  validateOpenDebitPosition,
} from "@/apps/tatar/cashBudget/views/tenants/components/rental-agreements/accounting/CBRentalAgreementOpenDebitPositions";
import { RentalAgreement } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import MultipleAssetLoaders from "@/components/AssetLoader/MultipleAssetLoaders";
import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators from "@/components/Form/Validation/FormValidators";
import StateWrapper from "@/components/StateWrapper/StateWrapper";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BaseAsset from "@/model/general-assets/BaseAsset";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import LanguageService from "@/services/LanguageService";
import { hasValue } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import React, { useState } from "react";
import { Field } from "react-final-form";
import AccountingFormAdditionalData from "../AccountingFormAdditionalData";
import AccountingRentalInfo from "../info/AccountingRentalInfo";
import "./AccountingInternBookingForm.scss";

interface AccountingInternBookingFormProps {
  onClose: () => void;
  onSuccess?: (asset: BaseAsset) => void;

  forAccount?: AccountingAccount;
  accounting?: AccountingData;
  accountTypes: AccountType[];
  baseAccount: string;

  title: string;

  transformSubmitValues?: (
    values: AccountingInternBookingFormValue
  ) => AccountingBookingFormValue;
}

const getInitialValue = (props: AccountingInternBookingFormProps) => {
  const { forAccount } = props;
  let account: AccountingAccount = null;
  if (forAccount) {
    account = forAccount;
  }
  return {
    entity: props.accounting.accounting.data.entity,
    objectId: account?.data?.objectId || null,
    account: account?._id || null,
    bookingText: `${props.title} - ${StringUtils.formatDate(new Date())}`,
    date: new Date(),
    bookings: { bookings: [], referenceField: "" },
    note: "",
    linkedAsset: [],
    attachments: [],
  } as Partial<AccountingInternBookingFormValue>;
};

const transformFormdataToSubmitdata = (
  props: AccountingInternBookingFormProps,
  values: AccountingInternBookingFormValue
) => {
  return props.transformSubmitValues
    ? props.transformSubmitValues(values)
    : ({
        groupDisplayName: values.bookingText,
        date: values.date,
        entity: values.entity,
        account: props.baseAccount,
        fullAmount: 0,
        note: values.note,
        linkedAsset: [
          ...values.linkedAsset,
          ...values.attachments.map((e) => ({
            assetType: AssetTypes.CashBudget.Attachment,
            assetId: e,
          })),
        ],

        frames: [
          {
            objectId: values.objectId,
            contraAccount: values.account,
            bookings: values.bookings.bookings
              .filter((e) => (e.value?.amount || 0) !== 0)
              .map((booking) => ({
                ...booking,
                referenceField:
                  booking.costId === null
                    ? values.bookings.referenceField
                    : null,
              })),
          },
        ],
      } as AccountingBookingFormValue);
};

export type AccountingInternBookingFormValue = {
  entity: string;
  objectId: string;
  account: string;
  bookingText: string;
  date: Date;
  bookings: OpenDebitPositionValue;
  note: string;
  linkedAsset: {
    assetType: string;
    assetId: string;
    extra?: any;
  }[];
  attachments: string[];
};

const AccountingInternBookingForm: React.FC<AccountingInternBookingFormProps> =
  (props) => {
    const [initialValue] = useState(getInitialValue(props));
    return (
      <FormStruct
        title={props.title}
        className={classNames("accounting-intern-booking-form")}
        onSubmit={async (values: AccountingInternBookingFormValue) => {
          const submitData: AccountingBookingFormValue =
            transformFormdataToSubmitdata(props, values);

          const result = await AccountingService.createBooking(submitData);
          props.onSuccess?.(result);
          props.onClose();
        }}
        ignoreSubmitOnEnter
        usePrompt
        //			 description={props.asset ? i18n.t("CBBookingCategoryRuleView.UpdateDescription", "Ändern Sie die Daten des Assets und speichern Sie.") : i18n.t("CBBookingCategoryRuleView.CreateDescription", "Erstellen Sie ein neues Asset und speichern Sie.")}
        submitText={i18n.t(
          "Accounting.AccountingInternBookingForm.Submit",
          "Speichern"
        )}
        onAbort={props.onClose}
        initialValues={initialValue}
        render={(form) => (
          <>
            <div className={`__flex`}>
              <Field
                name={`objectId`}
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className="__field">
                    <FormFieldValues names={["entity"]}>
                      {([entity]) => (
                        <BFChooserSelect
                          label={i18n.t(
                            "acc:AccountingBookingForm.objectId",
                            "Objekt"
                          )}
                          disabled={!entity || hasValue(props.forAccount)}
                          hideSubLabel
                          {...input}
                          {...FormValidators.getValidation(meta)}
                          // DataBus.emit("WHISPER", {
                          //   identifier: "accounting-entity-select",
                          //   type: "CLOSE",
                          // });
                          onChange={(value) => {
                            if (value !== input.value) {
                              // reset bookings
                              form.form.mutators.setValue(`bookings`, []);
                              form.form.mutators.setValue(`account`, null);
                            }
                            input.onChange(value);
                          }}
                          data={
                            entity
                              ? OrgaStruct.getObjectSelectOptions(entity)
                              : []
                            //   AccountingService.getAccountsOfEntityGrouped(
                            //   props.accounting
                            // )
                          }
                        />
                      )}
                    </FormFieldValues>
                  </div>
                )}
              </Field>
              <Field
                name={`account`}
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className="__field">
                    <FormFieldValues names={["entity", `objectId`]}>
                      {([entity, objectId]) => (
                        <AccountSelection
                          label={i18n.t(
                            "acc:AccountingBookingForm.account",
                            "Konto"
                          )}
                          accountTypes={props.accountTypes}
                          disabled={!entity || hasValue(props.forAccount)}
                          entity={entity}
                          objectId={objectId}
                          value={input.value}
                          onChange={(
                            value: string,
                            asset: AccountingAccount
                          ) => {
                            if (value !== input.value) {
                              // reset bookings
                              form.form.mutators.setValue(`bookings`, []);
                            }
                            input.onChange(value);
                          }}
                          {...FormValidators.getValidation(meta)}
                        />
                      )}
                    </FormFieldValues>
                  </div>
                )}
              </Field>
            </div>

            <FormFieldValues names={[`account`]}>
              {([account]) => {
                if (account) {
                  return (
                    <AssetLoader
                      inline
                      assetType={AssetTypes.Accounting.Account}
                      query={MQ.and(
                        MQ.eq("_id", account),
                        MQ.eq(
                          "data.linkedAsset.assetType",
                          AssetTypes.Rental.RentalAgreement
                        )
                      )}
                      render={(account: AccountingAccount) => (
                        <AccountingRentalInfo
                          accounting={props.accounting}
                          rentalAgreementId={account?.data.linkedAsset.assetId}
                        />
                      )}
                      renderError={() => null}
                    />
                  );
                }

                return null;
              }}
            </FormFieldValues>

            <AccountingFormAdditionalData accounting={props.accounting} />
            <FormFieldValues names={[`objectId`, "account"]}>
              {([objectId, account]) => (
                <>
                  {objectId && account && (
                    <AssetLoader
                      assetType={AssetTypes.Portfolio.Object}
                      id={objectId}
                      render={(object: OAObject) => (
                        <MultipleAssetLoaders
                          assets={object.data.feature?.immo?.accounting?.debitposition.map(
                            (debit) => ({
                              assetType: AssetTypes.Accounting.Account,
                              query: MQ.and(
                                MQ.eq(
                                  "data.linkedAsset.assetType",
                                  AssetTypes.Portfolio.Object
                                ),
                                MQ.eq("data.linkedAsset.assetId", object._id),
                                MQ.eq("data.linkedAsset.extra", debit.id)
                              ),
                            })
                          )}
                          render={(debitAccounts: AccountingAccount[]) => (
                            <Field
                              name={`bookings`}
                              validate={validateOpenDebitPosition(false)}
                            >
                              {({ input, meta }) => {
                                const renderPositions = (
                                  rentalAgreement: RentalAgreement
                                ) => (
                                  <MultipleAssetLoaders
                                    assets={[
                                      {
                                        assetType:
                                          AssetTypes.Accounting.Account,
                                        query: MQ.and(
                                          MQ.eq(
                                            "data.accountType",
                                            AccountType.debitor_deposit
                                          ),
                                          MQ.eq(
                                            "data.entity",
                                            rentalAgreement.data.entity
                                          ),
                                          MQ.eq("data.taxAccount.taxRate", 0)
                                        ),
                                      },
                                      {
                                        assetType:
                                          AssetTypes.Accounting.Account,
                                        query: MQ.and(
                                          MQ.eq(
                                            "data.accountType",
                                            AccountType.debitor_dunning
                                          ),
                                          MQ.eq(
                                            "data.entity",
                                            rentalAgreement.data.entity
                                          ),
                                          MQ.eq("data.taxAccount.taxRate", 0)
                                        ),
                                      },
                                    ]}
                                    render={(accounts: AccountingAccount[]) => (
                                      <StateWrapper initialValue={false}>
                                        {(showBalanced) => (
                                          <>
                                            <div>
                                              <BFCheckbox
                                                checked={showBalanced.value}
                                                onChange={(_, checked) =>
                                                  showBalanced.setValue(checked)
                                                }
                                              >
                                                {i18n.t(
                                                  "acc:AccountingBookingForm.showBalanced",
                                                  "Auch ausgeglichene Positionen anzeigen"
                                                )}
                                              </BFCheckbox>
                                            </div>
                                            <CBRentalAgreementOpenDebitPositions
                                              {...input}
                                              showBalanced={showBalanced.value}
                                              validation={
                                                meta.touched && meta.error
                                                  ? meta.error
                                                  : undefined
                                              }
                                              accountingData={props.accounting}
                                              // openAmount={openAmount}
                                              account={account}
                                              manualBookingPositions={[
                                                ...object.data.feature?.immo?.accounting?.debitposition
                                                  .map((debit) => {
                                                    const debitAccount =
                                                      debitAccounts.find(
                                                        (e) =>
                                                          e.data.linkedAsset
                                                            ?.extra === debit.id
                                                      );
                                                    if (
                                                      (rentalAgreement?.data
                                                        .taxable &&
                                                        debit.taxRate === 0) ||
                                                      (!rentalAgreement?.data
                                                        .taxable &&
                                                        debit.taxRate !== 0)
                                                    ) {
                                                      return null;
                                                    }

                                                    const paymentPosition =
                                                      rentalAgreement?.data.paymentPositions.find(
                                                        (e) => e.id === debit.id
                                                      );
                                                    return {
                                                      id: debit.id,
                                                      account:
                                                        debitAccount?._id,
                                                      name: LanguageService.translateLabel(
                                                        debit.displayName
                                                      ),
                                                      monthlyCost:
                                                        paymentPosition?.gross ||
                                                        null,
                                                      taxRate: debit.taxRate,
                                                    };
                                                  })
                                                  .filter((e) => e),

                                                ...accounts.map((account) => ({
                                                  id: account._id,
                                                  account: account._id,
                                                  name: LanguageService.translateLabel(
                                                    account.data.displayName
                                                  ),
                                                  monthlyCost: null,
                                                  taxRate: 0,
                                                })),
                                              ]}
                                            />
                                          </>
                                        )}
                                      </StateWrapper>
                                    )}
                                  />
                                );

                                return (
                                  <AssetLoader
                                    assetType={AssetTypes.Accounting.Account}
                                    id={account}
                                    render={(account: AccountingAccount) =>
                                      account?.data.linkedAsset?.assetId ? (
                                        <AssetLoader
                                          assetType={
                                            AssetTypes.Rental.RentalAgreement
                                          }
                                          id={account?.data.linkedAsset.assetId}
                                          renderError={() =>
                                            renderPositions(null)
                                          }
                                          render={(asset: RentalAgreement) =>
                                            renderPositions(asset)
                                          }
                                        />
                                      ) : (
                                        renderPositions(null)
                                      )
                                    }
                                  />
                                );
                              }}
                            </Field>
                          )}
                        />
                      )}
                    />
                  )}
                </>
              )}
            </FormFieldValues>
          </>
        )}
      />
    );
  };

export default AccountingInternBookingForm;
