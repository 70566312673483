import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { CustomFieldEditType } from "@/modules/customfields/CustomField.interface";
import CFAttachment, {
  NewAttachmentValue,
} from "@/modules/customfields/fields/CFAttachment";
import classNames from "classnames";
import { Field } from "react-final-form";
// import "./CBRentalAgreementFormWizardStep1UploadContract.scss";

export type CBRentalAgreementFormWizardStep1Value = {
  rentalAgreement: NewAttachmentValue[];
};

interface CBRentalAgreementFormWizardStep1UploadContractProps {
  initialValues?: CBRentalAgreementFormWizardStep1Value;
  onBack: () => void;
  onSuccess: (values: CBRentalAgreementFormWizardStep1Value) => Promise<void>;
}
const CBRentalAgreementFormWizardStep1UploadContract = (
  props: CBRentalAgreementFormWizardStep1UploadContractProps
) => {
  return (
    <FormStruct
      title={i18n.t("cb:RentalAgreement.Form.Step1.title", "Vertrag hochladen")}
      description={i18n.t(
        "cb:RentalAgreement.Form.Step1.description",
        "Laden Sie den Mietvertrag inklusiver aller Nachträge hoch, welche Sie im System erfassen wollen."
      )}
      onSubmit={async (values) => {
        props.onSuccess(values);
      }}
      submitText={i18n.t("Global.Buttons.continue")}
      onAbort={props.onBack}
      initialValues={props.initialValues}
      cancelText={i18n.t("Global.Buttons.cancel")}
      className={classNames(
        `cb-rental-agreement-form-wizard-step1-upload-contract`
      )}
      render={(form) => (
        <div>
          <Field
            name="rentalAgreement"
            validate={FV.compose(FV.required(), FV.min(1))}
          >
            {(field) => (
              <div className={`margin-bottom-20`}>
                <CFAttachment
                  field={field}
                  accept={{
                    "application/pdf": [],
                  }}
                  assetType={AssetTypes.Rental.RentalAgreement}
                  data={{
                    displayName: i18n.t(
                      "cb:RentalAgreement.Form.Files",
                      "Vertragsdokumente"
                    ),
                    fieldType: CustomFieldEditType.attachment,
                    id: "attachments",
                    validation: [
                      {
                        type: "required",
                      },
                      {
                        type: "min",
                        param: 1,
                      },
                    ],
                  }}
                />
              </div>
            )}
          </Field>
        </div>
      )}
    />
  );
};

export default CBRentalAgreementFormWizardStep1UploadContract;
