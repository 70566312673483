import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import { Field } from "react-final-form";
import FormStruct from "../../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../../components/Form/Validation/FormValidators";
import StructLoader from "../../../../../../../components/StructLoader/StructLoader";
import i18n from "../../../../../../../i18n";
import BFInput from "../../../../../../../modules/abstract-ui/forms/input/BFInput";
import { PortfolioSupplyUnit } from "../../../../../cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import SupplyUnitService from "../../../../../cashBudget/views/config/supply-unit-config/SupplyUnitService";
// import './APSupplyUnitMeterReadingForm.scss';

interface APSupplyUnitMeterReadingFormProps {
  onClose: () => void;
  asset: PortfolioSupplyUnit;
  lastReadingValue?: number;
}
const APSupplyUnitMeterReadingForm = (
  props: APSupplyUnitMeterReadingFormProps
) => {
  return (
    <StructLoader
      unitType={props.asset.data.type}
      structType="supplyUnit"
      render={() => (
        <FormStruct
          title={i18n.t(
            "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.addReading",
            "Zählerstand hinzufügen"
          )}
          onSubmit={async (values) => {
            await SupplyUnitService.addSupplyUnitMeaterReading(
              props.asset._id,
              values.date,
              values.value
            );
            props.onClose();
          }}
          onAbort={props.onClose}
          submitText={i18n.t(
            "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.save",
            "Hinzufügen"
          )}
          initialValues={{
            date: new Date(),
            value: props.lastReadingValue || 0,
          }}
          render={(form) => (
            <>
              <Field
                name="value"
                validate={FormValidators.compose(
                  FormValidators.required(),
                  FormValidators.min(0)
                )}
              >
                {({ input, meta }) => (
                  <div className={`__field`}>
                    <BFInput
                      focusOnMount
                      type="number"
                      {...input}
                      label={i18n.t(
                        "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.value",
                        "Zählerstand"
                      )}
                      prefix={
                        props.asset.data.unit
                      }
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  </div>
                )}
              </Field>
              <Field
                name="date"
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className={`__field`}>
                    <BFDatefield
                      {...input}
                      label={i18n.t(
                        "cb:Portfolio.Objekt.supplyUnit.create.meterReadings.date",
                        "Ablesedatum"
                      )}
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  </div>
                )}
              </Field>
            </>
          )}
        />
      )}
    />
  );
};

export default APSupplyUnitMeterReadingForm;
