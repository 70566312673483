import { AssetTypes } from "@/model/AssetTypes";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import DataBusDefaults from "@/services/DataBusDefaults";
import { useEffect, useState } from "react";
import { Modal, Nav } from "rsuite";
import CDNLink from "../../../../../components/CDNLink/CDNLink";
import FormStruct from "../../../../../components/Form/FormStruct/FormStruct";
import PDFViewer, {
  PDFHighlight,
} from "../../../../../components/PDFViewer/PDFViewer";
import DebugDataComponent from "../../../../../debug/DebugDataComponent";
import i18n from "../../../../../i18n";
import BFDropdown from "../../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import ExpressionHelper from "../../../../../modules/generic-forms/util/ExpressionHelper";
import { useViewportSelector } from "../../../../../redux/hooks";
import CacheService from "../../../../../services/CacheService";
import { InvoicePaymentTypeTags } from "../../../../AppConfigInterfaces";
import ACInvoiceUtils from "../../../../utils/ACInvoiceUtils";
import InvoiceService from "../../InvoiceService";
import { RAInvoice } from "../../RAInterfaces";
import RADeclineInvoice from "../DeclineInvoice/RADeclineInvoice";
import { RADocumentsView } from "../Documents/RADocumentsView";
import RAInvoiceForm from "../InvoiceForm/RAInvoiceForm";
import { InvoiceBaseFormModel } from "../InvoiceForm/RAInvoiceFormModel";
import RAInvoiceProcessHistory from "../InvoiceProcessHistory/RAInvoiceProcessHistory";
import {
  RACommentsView,
  RAHistoryView,
  RAPaymentsView,
} from "../RAInvoiceDetails";
import RAInvoiceEntryView from "../RAInvoiceEntryView";
import RAInvoiceCurrentTask from "./RAInvoiceCurrentTask";
import "./RAInvoiceDetailsComponent.scss";

type TabType =
  | "details"
  | "pdf"
  | "comments"
  | "history"
  | "payments"
  | "documents";
interface Props {
  invoice: RAInvoice;
  editable?: boolean;
  card?: boolean;
}

const RAInvoiceDetailsComponent = (props: Props) => {
  const [initialState, setInitialState] = useState(
    InvoiceService.getInitialDataForInvoice(props.invoice)
  );
  const [declineModal, setDeclineModal] = useState<boolean>(false);
  const viewportSelector = useViewportSelector();
  const [editActive, setEditActive] = useState<boolean>(
    props.editable || false
  );
  const [active, setActive] = useState<TabType>("details");
  const isSplitView = ["xxl", "xl", "lg"].indexOf(viewportSelector) !== -1;

  const [highlights, setHighlights] = useState<PDFHighlight[]>([]);

  useEffect(() => {
    if (active === "pdf" && isSplitView) {
      setActive("details");
    }
  }, [isSplitView, active]);

  const fileCdnData = (Object.entries(
    props.invoice?.data?.invoice?.file || {}
  ) || [])[0];

  return (
    <div
      className={`ra-invoice-details-component ${
        isSplitView ? "split-view" : ""
      } ${props.card ? "__card" : ""}`}
    >
      <div className="invoice-details">
        <div className={`nav-container`}>
          <Nav appearance="subtle" activeKey={active} onSelect={setActive}>
            <Nav.Item eventKey="details">
              {i18n.t("ra:InvoiceDetails.Tabs.Details")}
            </Nav.Item>
            {!isSplitView && (
              <Nav.Item eventKey="pdf">
                {i18n.t("ra:InvoiceDetails.Tabs.PDF")}
              </Nav.Item>
            )}
            <Nav.Item eventKey="comments">
              {i18n.t("ra:InvoiceDetails.Tabs.Comment")}
              {props.invoice?.communication?.numComments > 0 &&
                `(${props.invoice?.communication?.numComments})`}
            </Nav.Item>
            <Nav.Item eventKey="history">
              {i18n.t("ra:InvoiceDetails.Tabs.History")}
            </Nav.Item>
            {props.invoice?.data?.direction === "INCOMING" &&
              ACInvoiceUtils.checkPaymentTypeTag(
                props.invoice?.data?.invoice?.paymentType,
                InvoicePaymentTypeTags.SHOULD_BE_PAYED
              ) && (
                <Nav.Item eventKey="payments">
                  {i18n.t("ra:InvoiceDetails.Tabs.Payments", "Zahlungen")}
                </Nav.Item>
              )}
            <Nav.Item eventKey="documents">
              {i18n.t("ra:InvoiceDetails.Tabs.Documents", "Dokumente")} (
              {(props.invoice?.data?.attachments || []).length})
            </Nav.Item>
          </Nav>
          <div className={`action`}>
            <BFDropdown
              appearance="clear"
              label={i18n.t("ra:InvoiceDetails.Action", "Aktion")}
              items={[
                {
                  type: "button",
                  text: editActive
                    ? i18n.t("ra:InvoiceDetails.EndEdit", "Bearbeiten beenden")
                    : i18n.t("ra:InvoiceDetails.Edit", "Bearbeiten"),
                  // disabled:
                  //   props.invoice?.data?.invoice?.fields?.paymentType?.value ===
                  //     "storno" || props.invoice?.data?.payed,
                  onSelect: () => {
                    if (props.invoice?.data?.status === "init") {
                      DataBusDefaults.toast({
                        type: "warning",
                        duration: 4000,
                        text: i18n.t(
                          "ra:InvoiceDetails.EditingNotAllowed",
                          "Die Bearbeitung der Rechnung ist nicht erlaubt, solange sie noch im initialen Zustand ist. Bitte nutzen Sie hierfür die Hochladen-Maske."
                        ),
                      });
                    } else {
                      setEditActive(!editActive);
                    }
                  },
                },
                {
                  disabled:
                    props.invoice?.data?.status === "init" ||
                    (props.invoice?.data.direction === "INCOMING" &&
                      ACInvoiceUtils.checkPaymentTypeTag(
                        props.invoice?.data?.invoice?.paymentType,
                        InvoicePaymentTypeTags.IS_STORNO
                      )) ||
                    props.invoice?.data?.payed ||
                    props.invoice?.data?.status === "declined",
                  className: `decline-button`,
                  type: "button",
                  text: i18n.t(
                    "ra:InvoiceDetails.DeclineInvoice",
                    "Rechnung ablehnen"
                  ),
                  onSelect: () => {
                    setDeclineModal(true);
                  },
                },
              ]}
            />
          </div>
        </div>
        <DebugDataComponent data={props.invoice} />
        <div className="content">
          {active === "details" &&
            (editActive ? (
              <div>
                <div className={`id-label`}>
                  {props.invoice?.id} -{" "}
                  {UnitStruct.getUnitLabel(props.invoice?.data?.type)}
                </div>
                <FormStruct
                  initialValues={initialState}
                  actionsOnChange
                  submitText={i18n.t("Global.Buttons.save")}
                  cancelText={i18n.t("Global.Buttons.cancel")}
                  onSubmit={async (values: InvoiceBaseFormModel) => {
                    // TODO invoice - submit
                    const invoice = await InvoiceService.updateInvoice(
                      props.invoice._id,
                      values
                    );
                    setInitialState(
                      InvoiceService.getInitialDataForInvoice(invoice)
                    );
                    setEditActive(false);
                  }}
                  onAbort={() => {
                    // update initial state - new object reference resets the form
                    setInitialState(
                      InvoiceService.getInitialDataForInvoice(props.invoice)
                    );
                  }}
                  render={(renderProps) => (
                    <RAInvoiceForm
                      form={renderProps.form}
                      invoice={props.invoice}
                      onHighlight={(highlight) => {
                        setHighlights(
                          highlight
                            ? Array.isArray(highlight)
                              ? highlight
                              : [highlight]
                            : []
                        );
                      }}
                    />
                  )}
                />
                <RAInvoiceProcessHistory invoice={props.invoice} />
                <RAInvoiceCurrentTask invoice={props.invoice} />
              </div>
            ) : (
              <>
                <div className={`id-label`}>
                  {props.invoice?.id} -{" "}
                  {UnitStruct.getUnitLabel(props.invoice?.data?.type)}
                </div>
                <RAInvoiceEntryView
                  displayBeneath
                  showDependenices
                  disableEdit={props.invoice.data.status === "init"}
                  invoice={props.invoice}
                  onHighlight={(highlight) => {
                    setHighlights(
                      highlight
                        ? Array.isArray(highlight)
                          ? highlight
                          : [highlight]
                        : []
                    );
                  }}
                />
                <RAInvoiceCurrentTask invoice={props.invoice} />
              </>
            ))}
          {active === "pdf" && (
            <div className="invoice-pdf">
              {fileCdnData && (
                <CDNLink
                  assetType={AssetTypes.Invoice}
                  assetId={props.invoice?._id}
                  assetField={"data.invoice.file"}
                  cdnId={fileCdnData[0]}
                  filename={fileCdnData[1].filename}
                  fileKey={fileCdnData[1].key}
                  hasFolderReadPermissions={true}
                  render={(url) => (
                    <PDFViewer
                      zoomFactorStorageKey={"pdf_zoom_factor_full_view"}
                      url={url}
                      highlights={highlights}
                      filename={fileCdnData[1].filename}
                    />
                  )}
                />
              )}
              <div className={`inset-shadow`} />
            </div>
          )}
          {active === "comments" && <RACommentsView invoice={props.invoice} />}
          {active === "history" && <RAHistoryView invoice={props.invoice} />}
          {active === "payments" && <RAPaymentsView invoice={props.invoice} />}
          {active === "documents" && (
            <RADocumentsView invoice={props.invoice} />
          )}
        </div>
      </div>

      {isSplitView && (
        <div className="invoice-pdf">
          {fileCdnData && (
            <CDNLink
              assetType={"invoice"}
              assetId={props.invoice?._id}
              assetField={"data.invoice.file"}
              cdnId={fileCdnData[0]}
              filename={fileCdnData[1].filename}
              fileKey={fileCdnData[1].key}
              hasFolderReadPermissions={true}
              render={(url) => (
                <PDFViewer
                  zoomFactorStorageKey={"pdf_zoom_factor_modal_view"}
                  url={url}
                  highlights={highlights}
                  filename={fileCdnData[1].filename}
                />
              )}
            />
          )}
          <div className={`inset-shadow`} />
        </div>
      )}

      <Modal
        size={"lg"}
        open={declineModal}
        className="ra-book-invoice-decline-modal"
      >
        <div className="modal-content">
          <RADeclineInvoice
            showProcess
            task={props.invoice?.data?.status === "inApproval"}
            invoiceId={props.invoice?._id}
            onClose={() => setDeclineModal(null)}
            onFinish={() => {
              setDeclineModal(false);
              if (props.invoice?.intern?.invoiceTaskId) {
                CacheService.getData({
                  oType: "asset",
                  assetType: "task",
                  forceReload: true,
                  id: props.invoice?.intern?.invoiceTaskId,
                });
              }
              // dispatch(
              //   patchAssetData(props.asset?._id, {
              //     _deleted: true,
              //     _declined: true,
              //   })
              // );
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
export default RAInvoiceDetailsComponent;

interface ByConfigProps {
  _invoice: string;
  _editable?: string;
  editable?: boolean;
  params: any;
}
export const RAInvoiceDetailsComponentByConfig = (props: ByConfigProps) => {
  const invoice = ExpressionHelper.evaluateExpression(
    props._invoice,
    props.params
  );
  const editable = props._editable
    ? ExpressionHelper.evaluateExpression(props._editable, props.params)
    : props.editable;
  return <RAInvoiceDetailsComponent invoice={invoice} editable={editable} />;
};
