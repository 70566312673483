import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import BFChooserMultipleSelect from "@/modules/abstract-ui/forms/chooser/BFChooserMultipleSelect";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import BFAssetPDFViewer from "@/modules/abstract-ui/forms/pdf-marker/BFAssetPDFViewer";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { openEditContact } from "@/modules/contacts-module/ContactSelectMenuItem";
import ContactUtils from "@/modules/contacts-module/ContactUtils";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { isDefined } from "@/utils/Helpers";
import { useState } from "react";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBRentalAgreementContactForm.scss";

interface CBRentalAgreementContactFormProps {
  contactId: string;
  kind?: ObjectKind;
  rentalAgreement?: RentalAgreement;
  withMarkerSection?: boolean;
}
const CBRentalAgreementContactForm = (
  props: CBRentalAgreementContactFormProps
) => {
  const form = (
    <AssetLoader
      assetType={AssetTypes.Contact}
      id={props.contactId}
      render={(contact: Contact) => (
        <div>
          <div className={`__field`}>
            <Field
              name="contactOption.addressId"
              validate={FV.compose(FV.required())}
            >
              {({ input, meta }) => (
                <BFChooserSelect
                  {...input}
                  {...FV.getValidation(meta)}
                  label={`${i18n.t(
                    "cb:RentalAgreement.Form.Fields.addressId",
                    "Adresse"
                  )}*`}
                  hideSearch
                  data={ContactUtils.getAdressSelectOptions(contact)}
                  overlayBottomComponent={(close) => (
                    <div
                      className={`__flex __justify-center padding-left-10 padding-right-10`}
                    >
                      <BFButton
                        appearance="link"
                        onClick={() => {
                          close();
                          openEditContact(contact);
                        }}
                      >
                        {i18n.t(
                          "cb:RentalAgreement.Form.Fields.addAddress",
                          "Adresse hinzufügen"
                        )}
                      </BFButton>
                    </div>
                  )}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="contactOption.contactPersonId">
              {({ input, meta }) => (
                <BFChooserSelect
                  {...input}
                  {...FV.getValidation(meta)}
                  cleanable
                  overlayBottomComponent={(close) => (
                    <div
                      className={`__flex __justify-center padding-left-10 padding-right-10`}
                    >
                      <BFButton
                        appearance="link"
                        onClick={() => {
                          close();
                          openEditContact(contact);
                        }}
                      >
                        {i18n.t(
                          "cb:RentalAgreement.Form.Fields.addContactPerson",
                          "Kontaktperson hinzufügen"
                        )}
                      </BFButton>
                    </div>
                  )}
                  label={`${i18n.t(
                    "cb:RentalAgreement.Form.Fields.contactPersonId",
                    "Kontaktperson"
                  )}`}
                  hideSearch
                  data={ContactUtils.getContactPersonSelectOptions(contact)}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="contactOption.mail">
              {({ input, meta }) => (
                <BFChooserMultipleSelect
                  {...input}
                  {...FV.getValidation(meta)}
                  cleanable
                  overlayBottomComponent={(close) => (
                    <div
                      className={`__flex __justify-center padding-left-10 padding-right-10`}
                    >
                      <BFButton
                        appearance="link"
                        onClick={() => {
                          close();
                          openEditContact(contact);
                        }}
                      >
                        {i18n.t(
                          "cb:RentalAgreement.Form.Fields.addEmail",
                          "Email-Adresse hinzufügen"
                        )}
                      </BFButton>
                    </div>
                  )}
                  label={`${i18n.t(
                    "cb:RentalAgreement.Form.Fields.mail",
                    "Per E-Mail"
                  )}`}
                  hideSearch
                  data={ContactUtils.getEmailSelectOptions(contact)}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="contactOption.phone">
              {({ input, meta }) => (
                <BFChooserMultipleSelect
                  {...input}
                  {...FV.getValidation(meta)}
                  cleanable
                  overlayBottomComponent={(close) => (
                    <div
                      className={`__flex __justify-center padding-left-10 padding-right-10`}
                    >
                      <BFButton
                        appearance="link"
                        onClick={() => {
                          close();
                          openEditContact(contact);
                        }}
                      >
                        {i18n.t(
                          "cb:RentalAgreement.Form.Fields.addPhone",
                          "Nummer hinzufügen"
                        )}
                      </BFButton>
                    </div>
                  )}
                  label={`${i18n.t(
                    "cb:RentalAgreement.Form.Fields.phone",
                    "Per Anruf"
                  )}`}
                  hideSearch
                  data={ContactUtils.getPhoneSelectOptions(contact)}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="contactOption.sms">
              {({ input, meta }) => (
                <BFChooserMultipleSelect
                  {...input}
                  {...FV.getValidation(meta)}
                  cleanable
                  overlayBottomComponent={(close) => (
                    <div
                      className={`__flex __justify-center padding-left-10 padding-right-10`}
                    >
                      <BFButton
                        appearance="link"
                        onClick={() => {
                          close();
                          openEditContact(contact);
                        }}
                      >
                        {i18n.t(
                          "cb:RentalAgreement.Form.Fields.addPhone",
                          "Nummer hinzufügen"
                        )}
                      </BFButton>
                    </div>
                  )}
                  label={`${i18n.t(
                    "cb:RentalAgreement.Form.Fields.sms",
                    "Per SMS"
                  )}`}
                  hideSearch
                  data={ContactUtils.getPhoneSelectOptions(contact)}
                />
              )}
            </Field>
          </div>
        </div>
      )}
    />
  );

  if (props.withMarkerSection) {
    return (
      <Field name="marks.tenant">
        {({ input, meta }) => (
          <BFPDFMarkerSection
            marginBottom={20}
            {...FV.getValidation(meta)}
            title={
              <>
                <span className={`margin-left-6`}>
                  {i18n.t(
                    "cb:RentalAgreement.Form.Sections.tenant.title",
                    "Mieter"
                  )}
                </span>
              </>
            }
            {...input}
            editHint={i18n.t(
              "cb:RentalAgreement.Form.Fields.tenant.editHint",
              "Markieren Sie die Stellen, wo Sie die Informationen zum Mieter finden."
            )}
            viewHint={i18n.t(
              "cb:RentalAgreement.Form.Fields.automatic.viewHint",
              "Informationen zur Auszugsautomatik"
            )}
            identifier="tenant"
            pdfViewerIdentifier={"rental-agreement-pdf"}
            value={input.value}
            onChange={input.onChange}
          >
            {form}
          </BFPDFMarkerSection>
        )}
      </Field>
    );
  }
  return (
    <div className={`contactOption-form`}>
      <div
        className={`__flex section-title __h3  margin-top-10  margin-bottom-10`}
      >
        <div className={`title`}>
          {i18n.t(
            "cb:RentalAgreement.Form.SectionTitle.ContactOptions",
            "Kontaktdaten"
          )}
        </div>
      </div>
      {form}
    </div>
  );
};

export default CBRentalAgreementContactForm;

export const CBRentalAgreementContactFormButton = () => {
  return (
    <Field name="contactOption" allowNull defaultValue={null}>
      {({ input, meta }) => {
        if (!isDefined(input.value)) {
          return (
            <BFButton
              type="button"
              appearance="outline"
              size="xs"
              onClick={() =>
                input.onChange({
                  contactOptionBy: "tenant",
                  contactOptionsCount: 3,
                  contactOptionsUsed: 0,
                })
              }
              text={i18n.t(
                "cb:RentalAgreement.Form.Buttons.addContactOption",
                "ContactOption hinzufügen"
              )}
            />
          );
        } else {
          return null;
        }
      }}
    </Field>
  );
};

export const CBRentalAgreementContactOptionUpdateForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    contactOption: props.rentalAgreement.data.contactOption,
    marks: {
      tenant: props.rentalAgreement.data.marks?.tenant || [],
    },
  });
  const kind = ObjectKindStruct.getKind(
    OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.objectKindId
  );
  return (
    <FormStruct
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementContact(
          props.rentalAgreement._id,
          values.contactOption,
          values.marks
        );
        props.onClose();
      }}
      initialValues={initialValues}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t("cb:RentalAgreement.Form.SectionTitle.ContactOption", "Kontakt")
      )}
      submitText={i18n.t("Global.Buttons.save")}
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <BFAssetPDFViewer
            identifier="rental-agreement-pdf"
            height={"78vh"}
            label={{
              single: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreement",
                "Mietvertrag"
              ),
              multiple: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreements",
                "Mietverträge"
              ),
            }}
            asset={props.rentalAgreement}
            assetField="data.attachments"
            assetType={AssetTypes.Rental.RentalAgreement}
            directoryId={
              (
                kind.data.features.find(
                  (e) => e.type === "immo"
                ) as ObjectKindFeature_IMMO
              )?.rentalAgreementDirectory
            }
          />
        </div>
      )}
      render={(formProps) => (
        <>
          <CBRentalAgreementContactForm
            withMarkerSection
            rentalAgreement={props.rentalAgreement}
            kind={kind}
            contactId={props.rentalAgreement.data.tenant}
          />
        </>
      )}
    />
  );
};
