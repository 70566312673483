import FormStruct from "../../../../../../../components/Form/FormStruct/FormStruct";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import CBRentalService from "../../CBRentalService";
import { RentalUnit } from "../../TenantsInterfaces";
import { CBRentalUnitFormPlanDataFormPart } from "./CBRentalUnitForm";
import "./CBRentalUnitPlanDataForm.scss";

interface CBRentalUnitPlanDataFormProps {
  rentalUnit: RentalUnit;
  onClose: () => void;
}
const CBRentalUnitPlanDataForm = (props: CBRentalUnitPlanDataFormProps) => {
  const initialValues = {
    operatingCostGross: props.rentalUnit.data.operatingCostGross,
    operatingCostNet: props.rentalUnit.data.operatingCostNet,
    rentGross: props.rentalUnit.data.rentGross,
    rentNet: props.rentalUnit.data.rentNet,
    unitType: props.rentalUnit.data.unitType,
  };

  return (
    <FormStruct
      className={"cb-rental-unit-plan-data-form"}
      submitText={i18n.t(
        "cb:RentalUnit.PlanDataForm.SubmitPlanData",
        "Planmiete speichern"
      )}
      title={i18n.t("cb:RentalUnit.PlanDataForm.Title", "")}
      description={i18n.t(
        "cb:RentalUnit.PlanDataForm.Description",
        "Bitte geben Sie die aktuelle Marktmiete der Mieteinheit ein. Dieser Wert wird für die Berechnung der Leerstandsverluste verwendet."
      )}
      initialValues={initialValues}
      onSubmit={async (values) => {
        // todo submit data
        const result = await CBRentalService.submitRentalUnitPlanData(
          [
            {
              rentalUnitId: props.rentalUnit._id,
              operatingCostGross: values.operatingCostGross,
              operatingCostNet: values.operatingCostNet,
              rentGross: values.rentGross,
              rentNet: values.rentNet,
            },
          ],
          {
            assetType: AssetTypes.Rental.RentalUnit,
            id: props.rentalUnit._id,
          }
        );
        props.onClose();
      }}
      onAbort={props.onClose}
      render={({ form }) => {
        return (
          <>
            <CBRentalUnitFormPlanDataFormPart form={form} />
          </>
        );
      }}
    />
  );
};

export default CBRentalUnitPlanDataForm;
