import ContactUtils from "@/modules/contacts-module/ContactUtils";
import { handleError } from "@/utils/ErrorCodes";
import axios from "axios";
import i18n from "../i18n";
import { ListResponse } from "../model/common/HttpModel";
import {
  Contact,
  ContactData,
  ContactType,
  UpdateIndividualDataEntry,
} from "../model/db/Contact";
import { HTTP } from "../utils/Http";
import MQ from "../utils/MatchQueryUtils";
import CacheService from "./CacheService";
import ServiceUtils from "./ServiceUtils";

type FetchParam = {
  search: string;
  businessUnits: string[];
  contactTypes: ContactType[];
  offset: number;
  cancelToken: any;
};
const DEFAULT_LIST_LIIMIT = 20;
type ContactFormData = {
  overwrite?: UpdateIndividualDataEntry;
} & Partial<ContactData>;
class ContactServiceClass {
  async submitContact(
    data: ContactFormData,
    updateId?: string,
    force?: boolean
  ) {
    const individualData = data.overwrite;
    const dataTransformed: Partial<ContactData> = {
      ...data,
      address: data.address || [],
      contactPersons: data.contactPersons || [],
      phone: data.phone || [],
      emails: data.emails || [],
      contactType: data.contactType || [],
      bankAccount: data.bankAccount || [],
      ...{
        overwrite: undefined,
        // fix wrong elements
        id: undefined,
        customerUUID: undefined,
        notes: undefined,
        attachments: undefined,
        type: undefined,
        note: undefined,
        contactOverwrite: undefined,
      },
    };
    if (data.address?.length > 0 && !data.address.some((e) => e.isMain)) {
      throw {
        error: i18n.t(
          "Contact.noMainAddressError",
          "Bitte wählen Sie eine Hauptadresse aus."
        ),
      };
    }
    if (
      data.bankAccount?.length > 0 &&
      !data.bankAccount.some((e) => e.isMain)
    ) {
      throw {
        error: i18n.t(
          "Contact.noMainBankAccountError",
          "Bitte wählen Sie eine Hauptbankverbindung aus."
        ),
      };
    }
    let result;
    if (updateId) {
      result = await HTTP.post({
        target: "EMPTY",
        url: `/api/contact/${updateId}/updateContact`,
        bodyParams: {
          ...dataTransformed,
          displayName: ContactUtils.evaluateDisplayName(
            dataTransformed as ContactData
          ),
          status: "active",
        },
      });
    } else {
      result = await HTTP.post({
        target: "EMPTY",
        url: "/api/contact/createContact",
        bodyParams: {
          ...dataTransformed,
          displayName: ContactUtils.evaluateDisplayName(
            dataTransformed as ContactData
          ),
          status: "active",
          forceUpload: force,
          type: data.type,
        },
      });
    }

    if (individualData) {
      result = await this.updateIndividualData(result._id, individualData);
    }
    CacheService.update(result);

    return result;
  }

  async fetchContacts(param: FetchParam) {
    const data = (await HTTP.post({
      url: "asset/list/contact",
      bodyParams: {
        textQuery: param.search,
        matchQuery: MQ.and(
          MQ.in("data.contactType", param.contactTypes),
          MQ.in("data.type", param.businessUnits),
          MQ.eq("data.status", "active")
        ),
        skip: param.offset,
        limit: DEFAULT_LIST_LIIMIT,
      },
      cancelToken: param.cancelToken
        ? new axios.CancelToken((cancel) => (param.cancelToken.cancel = cancel))
        : undefined,
    })) as ListResponse<Contact>;
    return data;
  }

  async mergeContacts(
    target: Contact,
    source: Contact,
    update: boolean = true
  ) {
    try {
      const data = await HTTP.get({
        url: `/api/contact/${target._id}/merge/${source._id}?update=${update}`,
        target: "EMPTY",
      });
      // update result of merged element
      CacheService.update(data.merged);
      return { status: 200, data: data };
    } catch (err) {
      let context;
      switch (err.response.data?.message) {
        case "TARGET_CONTACT_INACTIVE":
          context = "target";
          break;
        case "SOURCE_CONTACT_INACTIVE":
          context = "sosurce";
          break;
        case "CONTACT_TYPE_MISMATCH":
          context = "mismatch";
          break;
        default:
          context = "default";
          break;
      }
      handleError(err, context);
      return err;
    }
  }

  async addNote(contactId: string, note: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/api/contact/${contactId}/createNote`,
        bodyParams: { note },
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async removeNote(contactId: string, noteId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/api/contact/${contactId}/deleteNode/${noteId}`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }

  async updateIndividualData(
    contactId: string,
    values: UpdateIndividualDataEntry
  ) {
    const newValues = {
      ...values,
      bill: {
        ...(values.bill || {}),
        sepaActive: undefined,
        sepa: values.bill?.sepaActive ? values.bill.sepa : null,
      },
      invoice: {
        ...(values.invoice || {}),
        sepaActive: undefined,
        sepa: values.invoice?.sepaActive ? values.invoice.sepa : null,
      },
    };
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/api/contact/${contactId}/updateIndividualData`,
        bodyParams: newValues,
        target: "EMPTY",
      });
      CacheService.update(result);
      return result;
    });
  }
}
const ContactService = new ContactServiceClass();
export default ContactService;
