import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import TechnicalUnitStruct from "@/redux/actions/struct/implemented/TechnicalUnitStruct";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";

export const showEndAgreementModal = (agreement: RentalAgreement) => {
  ModalManager.show({
    size: "fluid",
    noPadding: true,
    content: (state, setState, close) => {
      return <EndAgreementModal agreement={agreement} onClose={close} />;
    },
  });
};
export const EndAgreementModal = (props: {
  agreement: RentalAgreement;
  onClose: () => void;
}) => {
  TechnicalUnitStruct.getCategories();

  return (
    <FormStruct
      title={i18n.t(
        "cb:RentalAgreement.Form.Fields.endAgreement",
        "Mietvertrag beenden"
      )}
      onSubmit={async (values) => {
        await CBRentalService.endRentalAgreement(
          props.agreement._id,
          values.moveOut
        );
        props.onClose();
      }}
      submitText={i18n.t(
        "cb:RentalAgreement.Form.Fields.endAgreement",
        "Mietvertrag beenden"
      )}
      onAbort={props.onClose}
      description={i18n.t(
        "cb:RentalAgreement.Form.Fields.endAgreementDescription",
        "Bitte geben Sie das Datum des Mietvertragsendes ein."
      )}
      render={(form) => (
        <>
          <Field
            name={`moveOut`}
            validate={FV.compose(
              FV.required(),
              FV.dateAfter(new Date(props.agreement.data.moveIn))
            )}
          >
            {({ input, meta }) => (
              <BFDatefield
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.moveOut",
                  "Auszug"
                )}
                {...input}
                {...FV.getValidation(meta)}
              />
            )}
          </Field>
        </>
      )}
    />
  );
};

export const showRestartAgreementModal = (agreement: RentalAgreement) => {
  ModalManager.confirm({
    title: i18n.t(
      "cb:RentalAgreement.Form.Fields.restartAgreement",
      "Mietvertrag wieder aufnehmen"
    ),
    message: i18n.t(
      "cb:RentalAgreement.Form.Fields.restartAgreementMessage",
      "Möchten Sie den Mietvertrag wirklich wieder aufnehmen?"
    ),
    onConfirm: async () => {
      await CBRentalService.restartRentalAgreement(agreement);
    },
    confirmButtonText: i18n.t(
      "cb:RentalAgreement.Form.Fields.restartAgreement",
      "Wieder aufnehmen"
    ),
  });
};
