import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import ModalManager from "@/components/ModalComponent/ModalManager";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import classNames from "classnames";
import moment from "moment";
import AssetLoader from "../../../../../../../components/AssetLoader/AssetLoader";
import LoadPage from "../../../../../../../components/LoadPage/LoadPage";
import WrappedText from "../../../../../../../components/WrappedText/WrappedText";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { useAssetList } from "../../../../../../../redux/hooks";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import {
  RentalAgreement,
  RentalAgreementTimePeriod,
  RentalUnit,
} from "../../TenantsInterfaces";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "./CBRentalAgreementHelper";
import "./CBRentalAgreementPeriods.scss";
import CBRentalAgreementRentChange from "./CBRentalAgreementRentChange";
import React from 'react';

interface CBRentalAgreementPeriodsProps {
  rentalAgreement: RentalAgreement;
  kind: ObjectKind;
}
const CBRentalAgreementPeriods = (props: CBRentalAgreementPeriodsProps) => {
  const data = useAssetList<RentalAgreementTimePeriod>(
    AssetTypes.Rental.RentalAgreementPeriod,
    MQ.and(
      MQ.eq("data.status", "active"),
      MQ.eq("data.rentalAgreement", props.rentalAgreement._id)
    )
  );
  if (!data || data.state === "loading") {
    return <LoadPage />;
  }
  if (data?.state === "error") {
    return (
      <div className={`cb-rental-agreement-periods __card error-page`}>
        {i18n.t(
          "cb:RentalAgreementPeriods.error",
          "Fehler beim Laden der Mietpreis Übersicht"
        )}
      </div>
    );
  }
  return (
    <div className={classNames(`cb-rental-agreement-periods __card`)}>
      <div className={`periods-container`}>
        <div className={`periods-action`}>
          <BFButton
            appearance="outline"
            onClick={() => {
              ModalManager.show({
                backdrop: "static",
                noPadding: true,
                size: "lg",
                content: (states, setStates, closeModal) => (
                  <CBRentalAgreementRentChange
                    onClose={closeModal}
                    kind={props.kind}
                    rentalAgreementId={props.rentalAgreement._id}
                  />
                ),
              });
            }}
          >
            {i18n.t(
              "cb:RentalAgreementPeriods.addPeriod",
              "Neuen Mietzeitraum hinzufügen"
            )}
          </BFButton>
        </div>
        {data.data
          .sort((a, b) => moment(b.data.from).diff(moment(a.data.from)))
          .map((period, index, arr) => {
            return (
              <CBRentalAgreementPeriod
                period={period}
                previousPeriod={index < arr.length ? arr[index + 1] : undefined}
                key={period._id}
                rentalAgreement={props.rentalAgreement}
              />
            );
          })}
        <div className={`vert-line`} />
      </div>
    </div>
  );
};

export default CBRentalAgreementPeriods;

const CBRentalAgreementPeriod = (props: {
  period: RentalAgreementTimePeriod;
  rentalAgreement: RentalAgreement;
  previousPeriod?: RentalAgreementTimePeriod;
}) => {
  const object = OrgaStruct.getObject(props.rentalAgreement.data.objectId);
  const objectKind = ObjectKindStruct.getKind(object.objectKindId);

  const isCurrentPeriod = !props.period.data.to
    ? moment().isAfter(moment(props.period.data.from))
    : moment().isBetween(
        moment(props.period.data.from),
        moment(props.period.data.to)
      );
  const isFuturePeriod = moment().isBefore(moment(props.period.data.from));
  const isPastPeriod = moment().isAfter(moment(props.period.data.to));

  const addedRentalUnits = props.period.data.rentalUnitIds.filter(
    (unitId) =>
      !(props.previousPeriod?.data.rentalUnitIds || []).includes(unitId)
  );
  const removedRentalUnits = (
    props.previousPeriod?.data.rentalUnitIds || []
  ).filter((unitId) => !props.period.data.rentalUnitIds.includes(unitId));
  // const removedRentalUnits = (props.previousPeriod?.data.rentalUnitIds || []).filter(unitId => props.period.data.rentalUnitIds.includes(unitId));

  const reason = ObjectKindStruct.getCatalog(
    objectKind._id,
    "immo",
    "rentalChangeReason"
  ).find((e) => e.value === props.period.data.reason);
  return (
    <div
      className={classNames(`cb-rental-agreement-period`, {
        future: isFuturePeriod,
        past: isPastPeriod,
        current: isCurrentPeriod,
      })}
    >
      <div className={`head-line`}>
        <div className={`circle`}>
          {isCurrentPeriod && <div className={`current-circle`} />}
          {isPastPeriod && <div className={`past-circle`} />}
        </div>
        {isCurrentPeriod && (
          <span className={`current-period`}>
            {i18n.t("cb:RentalAgreementPeriod.currentlyActive", "Aktuell")}
          </span>
        )}
        {isFuturePeriod && (
          <span className={`future-period`}>
            {i18n.t("cb:RentalAgreementPeriod.futureActive", "Demnächst")}
          </span>
        )}
        {i18n.t("Global.Labels.fromDate", "ab")}{" "}
        {moment(props.period.data.from).format(i18n.t("Formats.dateFormat"))}{" "}
        {props.period.data.to &&
          `${i18n.t("Global.Labels.until", "bis")} ${moment(
            props.period.data.to
          ).format(i18n.t("Formats.dateFormat"))}`}
        <div className={`reason`}>
          {reason?.label ||
            i18n.t(
              "cb:RentalAgreementPeriod.defaultReason",
              "automatischer Mietzeitraum"
            )}
        </div>
        <DebugDataComponent data={props.period} />
      </div>
      <div className={`period-content`}>
        <div className={`rent-data`}>
          <div className={`entry-list rent`}>
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t("cb:RentalAgreement.Labels.rentNet", "Miete netto")}
              </div>
              <div className={`value`}>
                <span className={`should`}>
                  {StringUtils.formatCurrency(
                    props.period?.data?.rentNet,
                    true
                  )}
                </span>
              </div>
            </div>
            {props.rentalAgreement.data.taxable && (
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t(
                    "cb:RentalAgreement.Labels.rentGross",
                    "Miete brutto"
                  )}
                </div>
                <div className={`value`}>
                  <span className={`should`}>
                    {StringUtils.formatCurrency(
                      props.period.data.rentGross,
                      true
                    )}
                  </span>
                </div>
              </div>
            )}
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t(
                  "cb:RentalAgreement.Labels.operatingCostNet",
                  "Nebenkosten netto"
                )}
              </div>
              <div className={`value`}>
                <span className={`should`}>
                  {StringUtils.formatCurrency(
                    props.period.data.operatingCostNet,
                    true
                  )}
                </span>
              </div>
            </div>

            {props.rentalAgreement.data.taxable && (
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t(
                    "cb:RentalAgreement.Labels.operatingCostGross",
                    "Nebenkosten brutto"
                  )}
                </div>
                <div className={`value`}>
                  <span className={`should`}>
                    {StringUtils.formatCurrency(
                      props.period.data.operatingCostNet,
                      true
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        {addedRentalUnits.length > 0 && (
          <div className={`rental-units added`}>
            <div className={`__h4`}>
              {i18n.t(
                "cb:RentalAgreement.Labels.addedRentalUnits",
                "Hinzugefügte Mieteinheiten"
              )}
            </div>
            {/* <RentalUnitTableHeader /> */}

            <RentalUnitTableHeader />
            {addedRentalUnits.map((rentalUnit) => (
              <AssetLoader
                assetType={AssetTypes.Rental.RentalUnit}
                id={rentalUnit}
                render={(rentalUnit: RentalUnit) => (
                  <RentalUnitTableEntry rentalUnit={rentalUnit} asLink />
                )}
              />
            ))}
          </div>
        )}
        {removedRentalUnits.length > 0 && (
          <div className={`rental-units added`}>
            <div className={`__h4`}>
              {i18n.t(
                "cb:RentalAgreement.Labels.removedRentalUnits",
                "Entfernte Mieteinheiten"
              )}
            </div>
            {/* <RentalUnitTableHeader /> */}

            <RentalUnitTableHeader />
            {removedRentalUnits.map((rentalUnit) => (
              <AssetLoader
                assetType={AssetTypes.Rental.RentalUnit}
                id={rentalUnit}
                render={(rentalUnit: RentalUnit) => (
                  <RentalUnitTableEntry rentalUnit={rentalUnit} asLink />
                )}
              />
            ))}
          </div>
        )}
        {props.period.data.note && (
          <>
            <div className={`__h4 margin-top-10`}>
              {i18n.t("cb:RentalAgreement.Labels.note", "Notiz")}
            </div>
            <WrappedText text={props.period.data.note} />
          </>
        )}
      </div>
    </div>
  );
};
