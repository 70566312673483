import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import { FormApi } from "final-form";
import { useState } from "react";
import { Field } from "react-final-form";
import FormFieldValues from "../../../../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../../i18n";
import BFInput from "../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import StringUtils from "../../../../../../../utils/StringUtils";
import CBRentalService from "../../CBRentalService";
import {
  CB_RENTAL_UNIT_LIST,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import { RentalUnit, RentalUnitFormValue } from "../../TenantsInterfaces";
import "./CBRentalUnitForm.scss";

interface CBRentalUnitFormProps {
  rentalUnit?: RentalUnit;
  forObject?: string;
  kind: ObjectKind;
  onClose: () => void;
}

const CBRentalUnitForm = (props: CBRentalUnitFormProps) => {
  const [initialValues] = useState<RentalUnitFormValue>({
    id: props.rentalUnit.data.id,
    administratedFrom: props.rentalUnit.data.administratedFrom,
    administratedTo: props.rentalUnit.data.administratedTo,
    unitType: props.rentalUnit.data.unitType,
    area: props.rentalUnit.data.area,
    building: props.rentalUnit.data.building,
    displayName: props.rentalUnit.data.displayName,
    floor: props.rentalUnit.data.floor,
    operatingCostGross: props.rentalUnit.data.operatingCostGross,
    operatingCostNet: props.rentalUnit.data.operatingCostNet,
    quantity: props.rentalUnit.data.quantity,
    rentGross: props.rentalUnit.data.rentGross,
    rentNet: props.rentalUnit.data.rentNet,
    unitGroup: ObjectKindStruct.getUnitTypeBy(props.rentalUnit.data.unitType)
      ?.group,
  });

  return (
    <FormStruct
      className={"cb-rental-unit-form"}
      submitText={
        props.rentalUnit
          ? i18n.t("Global.Buttons.submit")
          : i18n.t("Global.Buttons.add")
      }
      title={
        props.rentalUnit
          ? i18n.t("cb:RentalUnit.Form.EditTitle", "Mieteinheit bearbeiten")
          : i18n.t("cb:RentalUnit.Form.AddTitle", "Mieteinheit hinzufügen")
      }
      description={
        props.rentalUnit
          ? i18n.t(
              "cb:RentalUnit.Form.EditDescription",
              "Bearbeiten Sie die Daten der Mieteinheit"
            )
          : i18n.t(
              "cb:RentalUnit.Form.AddDescription",
              "Füllen Sie die Daten der Mieteinheit aus"
            )
      }
      initialValues={initialValues}
      onSubmit={async (values) => {
        // todo submit data
        const result = await CBRentalService.submitRentalUnit(
          values,
          props.rentalUnit
        );

        DataBusDefaults.reload({
          identifiers: [CB_RENTAL_UNIT_LIST],
        });

        props.onClose();
      }}
      onAbort={props.onClose}
      render={({ form }) => {
        return (
          <>
            <div className={`section section-base-data`}>
              <div
                className={`section-title __h3 margin-bottom-10 margin-top-20`}
              >
                {i18n.t(
                  "cb:RentalUnit.Form.Sections.rentalUnitData",
                  "Mieteinheit"
                )}
              </div>
              <div className={`field-row`}>
                <div className={`__field`}>
                  <Field
                    name="id"
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.max(50)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.id",
                          "ID"
                        )}*`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field display-name`}>
                  <Field
                    name="displayName"
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.max(150)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.displayName",
                          "Name"
                        )}*`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={`field-row`}>
                <div className={`__field`}>
                  <Field
                    name="unitType"
                    validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => (
                      <BFSelect
                        {...input}
                        groupBy="group"
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.unitType",
                          "Nutzungsart"
                        )}*`}
                        sort={(isGroup) => {
                          if (isGroup) {
                            return (a, b) => {
                              let orderIndexA = a.children[0]?.groupOrder;
                              let orderIndexB = b.children[0]?.groupOrder;

                              return orderIndexA - orderIndexB;
                            };
                          }

                          return (a, b) => {
                            let nameA = a.label.toUpperCase();
                            let nameB = b.label.toUpperCase();

                            if (nameA < nameB) {
                              return -1;
                            }
                            if (nameA > nameB) {
                              return 1;
                            }
                            return 0;
                          };
                        }}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                        data={ObjectKindStruct.getUnitTypeSelectOptions(
                          props.kind._id
                        )}
                        onChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </div>

                <FormFieldValues names={["unitType"]}>
                  {([unitType]) => {
                    const unitTypeEl = ObjectKindStruct.getUnitTypeBy(unitType);
                    const group = getConfigRentalUnitTypeGroup(
                      unitTypeEl?.group
                    );

                    return (
                      <div className={`__field`}>
                        {group?.areaType === "count" && (
                          <Field
                            name="quantity"
                            validate={FormValidators.compose(
                              FormValidators.required(),
                              FormValidators.min(0)
                            )}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                type="number"
                                allowNull
                                step={1}
                                {...input}
                                label={`${i18n.t(
                                  "cb:RentalUnit.Form.Fields.quantityCount",
                                  "Anzahl"
                                )}*`}
                                validation={
                                  meta.error && meta.touched
                                    ? { level: "error", message: meta.error }
                                    : undefined
                                }
                              />
                            )}
                          </Field>
                        )}
                        {group?.areaType === "area" && (
                          <Field
                            name="area"
                            validate={FormValidators.compose(
                              FormValidators.required(),
                              FormValidators.min(0)
                            )}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                type="number"
                                allowNull
                                step={1}
                                prefix={StringUtils.getAreaUnit()}
                                {...input}
                                label={`${i18n.t(
                                  "cb:RentalUnit.Form.Fields.quantityArea",
                                  "Fläche"
                                )}*`}
                                validation={
                                  meta.error && meta.touched
                                    ? { level: "error", message: meta.error }
                                    : undefined
                                }
                              />
                            )}
                          </Field>
                        )}
                      </div>
                    );
                  }}
                </FormFieldValues>
              </div>

              <div className={`field-row`}>
                <div className={`__field field-building`}>
                  <Field
                    name="building"
                    // validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.building",
                          "Gebäude"
                        )}`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field field`}>
                  <Field
                    name="floor"
                    // validate={}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        type="number"
                        allowNull
                        step={1}
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.floor",
                          "Geschoss"
                        )}`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className={`section section-base-data`}>
              <div
                className={`section-title __h3 margin-bottom-10 margin-top-20`}
              >
                {i18n.t("cb:RentalUnit.Form.Sections.planData", "Plandaten")}
              </div>
              <CBRentalUnitFormPlanDataFormPart form={form} />
            </div>
            <div className={`section section-base-administration`}>
              {/**
               * status
               * from
               */}
              <div
                className={`section-title __h3 margin-bottom-10 margin-top-20`}
              >
                {i18n.t(
                  "cb:RentalUnit.Form.Sections.administration",
                  "Administrationsdaten"
                )}
              </div>
              <div className={`field-row`}>
                <div className={`__field`}>
                  <Field
                    name="administratedFrom"
                    validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => (
                      <BFDatefield
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.administratedFrom",
                          "Von"
                        )}*`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field`}>
                  <Field
                    name="administratedTo"
                    // validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => (
                      <BFDatefield
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.administratedTo",
                          "Bis"
                        )}`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default CBRentalUnitForm;

export const CBRentalUnitFormPlanDataFormPart = (props: { form: FormApi }) => {
  return (
    <>
      <FormFieldValues names={["unitType"]}>
        {([unitType]) => {
          const unitTypeObj = unitType
            ? ObjectKindStruct.getUnitTypeBy(unitType)
            : null;

          const taxFactor = unitTypeObj?.taxRate || 0;

          return (
            <>
              <div className={`field-row`}>
                <div className={`__field`}>
                  <FormFieldValues names={["rentGross"]}>
                    {([rentGross]) => (
                      <Field
                        name="rentNet"
                        validate={FormValidators.compose(
                          FormValidators.required(),
                          FormValidators.min(0)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            {...input}
                            prefix={StringUtils.getCurrencySymbol()}
                            textAlign={"left"}
                            type={"priceInput"}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.rentNet",
                              "Planmiete netto"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            onChange={(value: number) => {
                              input.onChange(value);
                              props.form.mutators.setValue(
                                "rentGross",
                                value * (taxFactor / 100 + 1)
                              );
                            }}
                            removeSuffixPadding
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
                <div className={`__field`}>
                  <FormFieldValues names={["rentNet"]}>
                    {([rentNet]) => (
                      <Field
                        name="rentGross"
                        validate={FormValidators.compose(
                          FormValidators.required(),
                          FormValidators.min(0)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            {...input}
                            prefix={StringUtils.getCurrencySymbol()}
                            textAlign={"left"}
                            type={"priceInput"}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.rentGross",
                              "Planmiete brutto"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            onChange={(value: number) => {
                              input.onChange(value);
                              props.form.mutators.setValue(
                                "rentNet",
                                value / (taxFactor / 100 + 1)
                              );
                            }}
                            removeSuffixPadding
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
              </div>

              <div className={`field-row`}>
                <div className={`__field`}>
                  <FormFieldValues names={["operatingCostGross"]}>
                    {([operatingCostGross]) => (
                      <Field
                        name="operatingCostNet"
                        validate={FormValidators.compose(
                          FormValidators.required(),
                          FormValidators.min(0)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            {...input}
                            prefix={StringUtils.getCurrencySymbol()}
                            textAlign={"left"}
                            type={"priceInput"}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.operatingCostNet",
                              "Nebenkosten netto"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            onChange={(value: number) => {
                              input.onChange(value);
                              props.form.mutators.setValue(
                                "operatingCostGross",
                                value * (taxFactor / 100 + 1)
                              );
                            }}
                            removeSuffixPadding
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
                <div className={`__field`}>
                  <FormFieldValues names={["operatingCostNet"]}>
                    {([operatingCostNet]) => (
                      <Field
                        name="operatingCostGross"
                        validate={FormValidators.compose(
                          FormValidators.required(),
                          FormValidators.min(0)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            {...input}
                            prefix={StringUtils.getCurrencySymbol()}
                            textAlign={"left"}
                            type={"priceInput"}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.operationCostGross",
                              "Nebenkosten brutto"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            onChange={(value: number) => {
                              input.onChange(value);
                              props.form.mutators.setValue(
                                "operatingCostNet",
                                value / (taxFactor / 100 + 1)
                              );
                            }}
                            removeSuffixPadding
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
              </div>
            </>
          );
        }}
      </FormFieldValues>
    </>
  );
};
