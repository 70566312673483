// import './UserReplacementForm.scss';

import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BFAssignment from "@/modules/abstract-ui/forms/assignment/BFAssignment";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { useState } from "react";
import { Field } from "react-final-form";
import UserReplacementService from "./UserReplacementService";

interface UserReplacementFormProps {
  onClose: () => void;
  onSuccess: () => void;
}
const UserReplacementForm = (props: UserReplacementFormProps) => {
  const [initial] = useState({
    replacementUser: [],
    from: new Date(),
    to: null,
  });
  return (
    <FormStruct
      initialValues={initial}
      allowOverflow
      onAbort={props.onClose}
      title={i18n.t(
        "UserProfile.Replacement.Form.Title",
        "Vertretung beantragen"
      )}
      onSubmit={async (values) => {
        await UserReplacementService.createReplacementRequest({
          from: values.from,
          to: values.to,
          replacementUser: values?.replacementUser[0]?.id,
          message: values.message,
        });
        props.onSuccess?.();
        props.onClose();
      }}
      submitText={i18n.t(
        "UserProfile.Replacement.Form.Submit",
        "Anfrage senden"
      )}
      description={i18n.t(
        "UserProfile.Replacement.Form.Description",
        "Geben Sie an, wann Sie nicht verfügbar sind und wer Sie vertreten soll. Die vertetende Person wird benachrichtigt und kann der Vertretung zustimmen oder ablehnen."
      )}
      render={(formProps) => (
        <>
          <div className={`__flex`}>
            <div className="__field">
              <Field name="from" validate={FV.compose(FV.required())}>
                {({ input, meta }) => (
                  <BFDatefield
                    {...input}
                    preventNull
                    label={i18n.t(
                      "UserProfile.Replacement.Form.From",
                      "Abwesenheit ab"
                    )}
                    {...FV.getValidation(meta)}
                  />
                )}
              </Field>
            </div>
            <div className="__field">
              <FormFieldValues names={["from"]}>
                {([from]) => (
                  <Field
                    name="to"
                    validate={FV.compose(FV.required(), FV.dateAfter(from))}
                  >
                    {({ input, meta }) => (
                      <BFDatefield
                        preventNull
                        {...input}
                        label={i18n.t("UserProfile.Replacement.Form.To", "bis")}
                        {...FV.getValidation(meta)}
                      />
                    )}
                  </Field>
                )}
              </FormFieldValues>
            </div>
          </div>

          <div className={`__field`}>
            <Field name="replacementUser" validate={FV.compose(FV.required())}>
              {({ input, meta }) => (
                <BFAssignment
                  identifier="replacement-form-user"
                  assignmentType="user"
                  asOverlay
                  maxAssignments={1}
                  textOverwrite={{}}
                  {...input}
                  {...FV.getValidation(meta)}
                  label={i18n.t(
                    "UserProfile.Replacement.Form.ReplacementUser",
                    "Vertretung"
                  )}
                />
              )}
            </Field>
          </div>

          <div className={`__field`}>
            <Field name="message">
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  {...FV.getValidation(meta)}
                  label={i18n.t(
                    "UserProfile.Replacement.Form.Message",
                    "Grund"
                  )}
                  type="textarea"
                  autoResize
                />
              )}
            </Field>
          </div>
        </>
      )}
    />
  );
};

export default UserReplacementForm;
