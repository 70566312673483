import classNames from "classnames";
import AssetLoader from "../../../components/AssetLoader/AssetLoader";
import i18n from "../../../i18n";
import BaseAsset from "../../../model/general-assets/BaseAsset";
import BFPlaceholder from "../../abstract-ui/general/Placeholder/BFPlaceholder";
import BFUseTooltip from "../../abstract-ui/general/tooltip/BFUseTooltip";
import "./ObjectRelatedLabel.scss";
import ObjectRelatedUtils from "./ObjectRelatedUtils";

interface ObjectRelatedLabelProps {
  assetId: string;
  assetType: string;
  withType?: boolean;
  className?: string;
}
const ObjectRelatedLabel = (props: ObjectRelatedLabelProps) => {
  if (props.assetId === undefined || props.assetType === undefined) return null;
  return (
    <AssetLoader
      assetType={props.assetType}
      id={props.assetId}
      renderLoading={() => (
        <BFPlaceholder loading width={80}>
          {" "}
        </BFPlaceholder>
      )}
      render={(asset: BaseAsset) => {
        if (!asset) {
          return null;
        }
        const related = ObjectRelatedUtils.getDataOfObjectRelated(
          props.assetType,
          asset
        );
        if (!related) {
          return null;
        }
        return (
          <span className={classNames("object-related-label", props.className)}>
            {props.withType && (
              <span className="object-related-label__type">
                {ObjectRelatedUtils.getNameOfObjectRelated(props.assetType)}{" "}
              </span>
            )}
            {related?.id && (
              <span className={`object-related-label__id`}>
                {related.id} -{" "}
              </span>
            )}
            <span className={`object-related-label__name`}>{related.name}</span>
          </span>
        );
      }}
    />
  );
};

export default ObjectRelatedLabel;

interface ActivitiesLabelProps {
  objectRelatedList: { assetId: string; assetType: string }[];
  withType?: boolean;
  className?: string;
}
export const ObjectRelatedMultipleLabel = (props: ActivitiesLabelProps) => {
  if (
    props.objectRelatedList === undefined ||
    !Array.isArray(props.objectRelatedList) ||
    props.objectRelatedList.length === 0
  ) {
    return null;
  }
  if (props.objectRelatedList.length === 1) {
    return (
      <ObjectRelatedLabel
        assetId={props.objectRelatedList[0].assetId}
        assetType={props.objectRelatedList[0].assetType}
        withType={props.withType}
        className={props.className}
      />
    );
  } else {
    return (
      <BFUseTooltip
        tooltip={
          <div className={`object-related-list-tooltip`}>
            {props.objectRelatedList.map((objectRelated, index) => (
              <div className={`object-related-label-container`}>
                <ObjectRelatedLabel
                  key={index}
                  assetId={objectRelated.assetId}
                  assetType={objectRelated.assetType}
                  withType={props.withType}
                />
              </div>
            ))}
          </div>
        }
      >
        <span
          className={classNames("object-related-list-label", props.className)}
        >
          {props.objectRelatedList.length}{" "}
          {i18n.t("ObjectRelatedLabel.objectRelatedList", "Zuordnungen")}
        </span>
      </BFUseTooltip>
    );
  }
};
