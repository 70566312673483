import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { ContactPerson } from "@/model/db/Contact";
import BFEntries from "@/modules/abstract-ui/data/entries/BFEntries";
import Tools from "@/utils/Tools";
import classNames from "classnames";
import { useRef } from "react";
import "./ContactBaseContactPersons.scss";

interface ContactBaseContactPersonsProps {
  contactPersons: ContactPerson[];
}
const ContactBaseContactPersons = (props: ContactBaseContactPersonsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className={classNames(`contact-base-address`)} ref={ref}>
      <BFEntries
        emptyText={i18n.t(
          "ContactBaseDataView.noAddress",
          "Keine Bankkonten vorhanden"
        )}
        entries={props.contactPersons.map((contactPerson) => {
          const addAddressToClipboard = () => {
            const textToCopy = [
              [contactPerson.firstName, contactPerson.lastName]
                .filter(Boolean)
                .join(" "),
              contactPerson.email,
              contactPerson.phone,
            ]
              .filter(Boolean)
              .join("\n");
            Tools.copy2Clipboard(
              textToCopy,
              i18n.t(
                "Contact.BankAccount.copiedToClipboard",
                "Kontaktperson in Zwischenablage kopiert"
              ),
              ref.current
            );
          };

          return {
            content: (
              <>
                <DebugDataComponent data={contactPerson} />
                <div className={`name`}>
                  {[contactPerson.firstName, contactPerson.lastName]
                    .filter(Boolean)
                    .join(" ")}
                </div>
                <div>
                  {contactPerson.email && (
                    <div className={`email`}>{contactPerson.email}</div>
                  )}
                  {contactPerson.phone && (
                    <div className={`phone`}>{contactPerson.phone}</div>
                  )}
                  {contactPerson.note && (
                    <div className={`note __sub-text margin-top-5`}>
                      {contactPerson.note}
                    </div>
                  )}
                </div>
              </>
            ),
            actions: [
              {
                icon: "duplicate-file",
                onClick: addAddressToClipboard,
                tooltip: i18n.t(
                  "ContactBaseAddress.copyToClipboard",
                  "In Zwischenablage kopieren"
                ),
              },
              ...(contactPerson.email
                ? [
                    {
                      icon: "paginate-filter-mail",
                      href: `mailto:${contactPerson.email}`,
                      tooltip: i18n.t(
                        "ContactBaseEmail.sendMail",
                        "Email senden"
                      ),
                    },
                  ]
                : []),
              ...(contactPerson.phone
                ? [
                    {
                      icon: "phone-actions-call",
                      href: `tel:${contactPerson.phone}`,
                      tooltip: i18n.t("ContactBasePhone.call", "Anrufen"),
                    },
                  ]
                : []),
            ],
          };
        })}
      />
    </div>
  );
};

export default ContactBaseContactPersons;
